import './style.less';

import { WATCHLIST_TAB_KEY } from '@aminsights/shared';
import cx from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { PageResults } from '@/components';
import Tabs, { TabItemProps } from '@/components/Tabs';
import { WATCHLIST_TABS } from '@/constants';
import useTabsToShowOnWatchlist from '@/hooks/query-hooks/watchlist-hooks/useTabsToShowOnWatchlist';
import ExploreSearchV2, {
  renderOptions,
} from '@/partials/ExploreSearchBox/ExploreSearchBoxV2';
import { useSearchBox } from '@/partials/ExploreSearchBox/useExploreSearchbox';
import useIsMobileView from '@/utils/useIsMobileView';

import BasePageWithMetadata from '../BasePageWithMetadata';
import { EmptySearch } from './components/EmptySearch';
import ExploreFilters from './components/ExploreFilters';
import ExploreTable from './components/ExploreTable';
import {
  useExploreContext,
  useExploreDefaultFiltersQuery,
  useExploreQuery,
} from './context';
import useProvideExplore, {
  convertParamsToState,
  convertStateToString,
} from './useProvideExplore';

const Explore: React.FCWithChild = () => {
  const location = useLocation();
  const history = useHistory();
  const mountedRef = useRef(false);
  const loadedFromUrlRef = useRef(true);
  const { path } = useRouteMatch();
  const [activeTab, setActiveTab] = useState<WATCHLIST_TAB_KEY>(
    WATCHLIST_TAB_KEY.SUMMARY,
  );
  const { data: tabsToShow } = useTabsToShowOnWatchlist('all');

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location?.search]);

  useEffect(() => {
    const { pathname } = location;
    const currentTab = [...(tabsToShow ?? [])].find(word =>
      pathname.includes(word),
    ) as WATCHLIST_TAB_KEY;
    setActiveTab(currentTab);
  }, [tabsToShow]);

  const { setSearchParameters, setFilterParameters } = useProvideExplore();
  const { state } = useExploreContext();
  const { data: defaultfiltersOptions, isLoading: isLoadingDefaultFilters } =
    useExploreDefaultFiltersQuery();
  const searchBoxOpts = useSearchBox({
    defaultValue: state.searchParameters.term,
  });
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useExploreQuery({
      queryParams: convertParamsToState(
        searchParams,
        {
          categories: defaultfiltersOptions?.categories,
          iaSectors: defaultfiltersOptions?.iaSectors,
          providers: defaultfiltersOptions?.providerNames || [],
        },
        true,
      ),
      enabled:
        Boolean(searchParams.toString()) &&
        Boolean(defaultfiltersOptions) &&
        !isLoadingDefaultFilters &&
        !loadedFromUrlRef.current,
    });
  const totalCount = data?.pages?.[0]?.data.totalCount || 0;

  useEffect(() => {
    // We must reset page when we switch teh tab to stop preserving old page number
    if (state.searchParameters.page !== 1) {
      setSearchParameters({ ...state.searchParameters, page: 1 });
    }
  }, [activeTab]);

  useEffect(() => {
    if (mountedRef.current) {
      const search = convertStateToString({
        ...state.searchParameters,
        ...state.filterParameters,
      });
      history.replace({ search });
    }
    mountedRef.current = true;
  }, [state.searchParameters, state.filterParameters]);

  useEffect(() => {
    if (loadedFromUrlRef.current && Boolean(searchParams.toString())) {
      // This should only run on first load of the page
      // This is to sync url state and app state
      const { term, sortDirection, sortKey, yearDropDown, ...restParams } =
        convertParamsToState(searchParams, null, true);
      setSearchParameters({
        term,
        sortDirection,
        sortKey,
        yearDropDown,
      });
      setFilterParameters(restParams);
    }
    loadedFromUrlRef.current = false;
  }, [searchParams, state.searchParameters.page, state.searchParameters.size]);

  const onNavigateTab = (tab?: string) => {
    let currentTab = tab;
    if (!tab) currentTab = WATCHLIST_TAB_KEY.SUMMARY;
    setActiveTab(currentTab as WATCHLIST_TAB_KEY);
    history.push(`${path}/${currentTab}?${searchParams.toString()}`);
  };

  const tabItems = useMemo(() => {
    const tabs: TabItemProps[] = [];

    WATCHLIST_TABS.filter(wt => {
      if (wt.key === WATCHLIST_TAB_KEY.CORRELATION_MATRIX) return false;
      return tabsToShow?.some(tts => tts === wt.key);
    }).forEach(tab =>
      tabs.push({
        ...tab,
        children: (
          <ExploreTable
            fetchNextPage={() => fetchNextPage()}
            currentTab={tab.key}
            hasNextPage={hasNextPage}
            data={data?.pages.flatMap(p => p.data.data || []) || []}
            loading={isLoading}
            loadingNextPage={isFetchingNextPage}
            showDropdown={tab.key === WATCHLIST_TAB_KEY.RISK}
            activeTab={activeTab}
          />
        ),
      }),
    );
    return tabs;
  }, [activeTab, tabsToShow, data, isLoading, hasNextPage, isFetchingNextPage]);

  const isMobile = useIsMobileView();

  return (
    <BasePageWithMetadata title={'Explore'}>
      <section className={cx('section-explore', 'pt-4')}>
        <h2 className="px-4 pt-2 mb-4 lg:mb-0 text-xl font-bold text-darkest">
          Explore
        </h2>
        <div className="flex flex-row lg:p-4">
          <div className="pt-1 mb-6 section-explore-wrapper bg-white lg:rounded-lg">
            <div className="section-explore-head">
              <div className="section-explore-head__search-bar">
                <ExploreSearchV2
                  dataTestId="exploreSearchBox"
                  options={searchBoxOpts.allSuggestions.map(opt =>
                    renderOptions({
                      value: opt.shareClassDetails.isin,
                      title: opt.shareClassDetails.code,
                      highlightTerm: searchBoxOpts.searchValue,
                      primaryShareClass: opt.primaryShareClass,
                    }),
                  )}
                  value={searchBoxOpts.searchValue}
                  onSelect={searchBoxOpts.onSearchResults}
                  onChange={searchBoxOpts.onChange}
                  onSubmit={searchBoxOpts.onSearchResults}
                  loading={searchBoxOpts.isLoading}
                />
              </div>
              <ExploreFilters />
            </div>
            {!totalCount && !isLoading ? (
              <EmptySearch
                title="Looking for a fund or investment trust?"
                subTitle="Use the search bar or apply filters."
              />
            ) : (
              <div className="section-explore-body">
                {/* TODO: need to share this component: found in watchlist and explore */}
                <Tabs
                  activeKey={activeTab}
                  onChange={onNavigateTab}
                  items={tabItems}
                  tabBarExtraContent={
                    <div className="flex items-center pr-4">
                      <PageResults totalCount={totalCount} />
                      {!isMobile && (
                        <div id="dropdown-portal" className="ml-4"></div>
                      )}
                    </div>
                  }
                  renderTabBar={(defaultProps, DefaultComponent) => (
                    <>
                      <DefaultComponent {...defaultProps} />
                      <div className="flex justify-end items-center w-full p-4 bg-white md:hidden">
                        <PageResults totalCount={totalCount} />
                        {isMobile && (
                          <div id="dropdown-portal" className="ml-4"></div>
                        )}
                      </div>
                    </>
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </BasePageWithMetadata>
  );
};

export default Explore;
