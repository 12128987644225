import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { Skeleton, Tooltip } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { Fragment } from 'react';

import { useCurrentBucket } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';

import CorrelationMatrixError from './components/CorrelationMatrixError';
import { MatrixFundName, MatrixNumber } from './components/MatrixFundName';
import {
  CorrelationMatrixColorLegend,
  getCorrelationValueColor,
} from './CorrelationMatrixColorGradient';
import style from './style.module.less';
import useCorrelationMatrixQuery from './useCorrelationMatrixQuery';

const CorrelationMatrix = () => {
  const currentBucket = useCurrentBucket();

  const isinsInCurrentBucket = currentBucket?.funds.map(f => f.isin);
  const query = useCorrelationMatrixQuery(isinsInCurrentBucket);

  if (query.isError && !query.isFetching) {
    return (
      <CorrelationMatrixError
        onRetry={() => {
          query.refetch();
        }}
      />
    );
  }

  const { rawMatrix } = query.data ?? {};

  const isins: string[] = [];

  if (query.isLoading && isinsInCurrentBucket) {
    isins.push(...isinsInCurrentBucket);
  } else {
    isins.push(...(query.data?.rawIsinByIndex ?? []));
  }

  const loadingArray = Array.from({
    length: currentBucket?.funds.length ?? 0,
  }).map(() =>
    Array.from({ length: currentBucket?.funds.length ?? 0 }).map(() => 0),
  );
  const outputMatrix = query.isLoading ? loadingArray : rawMatrix ?? [];
  const to = query.data?.queriedDateRange?.to;

  return (
    <div className="p-5">
      <div className="mb-3 grid gap-1 md:grid-cols-2">
        <p className="font-bold text-sm">Correlation Matrix</p>
        {to && (
          <p className="text-xs md:text-right">
            <strong>3 years</strong> as at{' '}
            <i>{dayjs(to).format(DISPLAY_DATE_FORMAT)}</i>
          </p>
        )}
      </div>
      <div className={cx('grid gap-4', style['legend-right'])}>
        <div className={cx('grid gap-2 md:gap-3', style['matrix-label-grid'])}>
          <div className="w-8 md:w-60">
            {isins?.map((isin, index) => {
              const fund = currentBucket?.funds.find(f => f.isin === isin);
              return (
                <Fragment key={`${isin}-${index}-key`}>
                  <MatrixNumber
                    index={index}
                    fundName={fund?.fundName ?? ''}
                    className="h-full md:hidden py-2"
                    spanClassName="leading-10 w-auto"
                    isFeatured={fund?.isFeatured}
                    isLoading={query.isLoading}
                  />
                  <MatrixFundName
                    fundName={fund?.shareClassDetailsCode ?? ''}
                    isin={fund?.isin ?? ''}
                    index={index}
                    isFeatured={fund?.isFeatured}
                    className="hidden md:flex"
                    isLoading={query.isLoading}
                  />
                </Fragment>
              );
            })}
          </div>
          <div
            className={cx(
              'relative',
              query.isLoading ? 'overflow-hidden' : 'overflow-auto',
            )}
          >
            {query.isLoading && (
              <Skeleton.Node
                fullSize
                active
                className={cx(
                  'absolute top-0 left-0 w-full h-full',
                  style['override-skeleton'],
                )}
                children={<></>}
              />
            )}
            {outputMatrix?.map((y, yIndex) => {
              const matrixRowKey =
                currentBucket?.funds[yIndex].shareClassDetailsCode || yIndex;

              return (
                <div
                  className={cx('grid', style['matrix-item-height'])}
                  style={{ gridTemplateColumns: `repeat(${y.length}, 1fr)` }}
                  key={`${matrixRowKey}`}
                >
                  {y.map((x, xIndex) => {
                    const showSkeleton = query.isLoading;
                    const shouldShow = yIndex >= xIndex;
                    const value = new Decimal(x).toDecimalPlaces(2).toNumber();
                    const isEmptyValue = isNaN(value);
                    const roundedValue = isEmptyValue ? '-' : value.toFixed(2);
                    const color = getCorrelationValueColor(
                      isEmptyValue ? '-' : value,
                    );
                    const output = value === 1 ? '1.00' : roundedValue;
                    const matrixColumnKey =
                      currentBucket?.funds[xIndex].isin || xIndex;

                    return (
                      <Fragment key={`${matrixRowKey}-${matrixColumnKey}`}>
                        {showSkeleton && (
                          <div
                            className="w-full h-full bg-black relative z-10 border-gray-100 border-solid border"
                            style={{
                              minWidth: '56px',
                              borderColor: 'rgba(204, 204, 208, 0.4)',
                              backgroundColor: !shouldShow
                                ? 'white'
                                : 'transparent',
                            }}
                          />
                        )}
                        {!showSkeleton && (
                          <Tooltip
                            open={shouldShow ? undefined : false}
                            placement="bottom"
                            overlayClassName="pointer-events-none max-w-none"
                            align={{
                              offset: [0, -20],
                            }}
                            color="#313341"
                            title={
                              <div className="text-xs whitespace-nowrap">
                                <p>
                                  {
                                    currentBucket?.funds[yIndex]
                                      .shareClassDetailsCode
                                  }
                                </p>
                                <p>
                                  {
                                    currentBucket?.funds[xIndex]
                                      .shareClassDetailsCode
                                  }
                                </p>
                              </div>
                            }
                          >
                            <div
                              className={cx(
                                'text-xs h-full grid items-center justify-center border-gray-100 border-solid border text-white',
                                isEmptyValue && 'text-neutral-450',
                              )}
                              style={{
                                minWidth: '56px',
                                borderColor: 'rgba(204, 204, 208, 0.4)',
                                backgroundColor: shouldShow
                                  ? color
                                  : 'transparent',
                              }}
                            >
                              {shouldShow ? output : ''}
                            </div>
                          </Tooltip>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              );
            })}
            <div
              className={cx(
                'grid h-14 bg-white relative',
                style['matrix-item-width'],
              )}
              style={{
                gridTemplateColumns: `repeat(${outputMatrix.length}, 1fr)`,
              }}
            >
              {Array.from({ length: outputMatrix.length }).map((_, index) => {
                const matchingFund = currentBucket?.funds.find(
                  f => f.isin === isins[index],
                );
                return (
                  <div
                    className={cx(style['matrix-item-width'])}
                    key={matchingFund?.isin}
                  >
                    <MatrixNumber
                      fundName={matchingFund?.fundName ?? ''}
                      isLoading={query.isLoading}
                      index={index}
                      className="w-14"
                      spanClassName="w-full"
                      isFeatured={matchingFund?.isFeatured}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <CorrelationMatrixColorLegend isLoading={query.isLoading} />
      </div>
      <br />
      <div className="md:hidden">
        {isins.map((isin, index) => {
          const fund = currentBucket?.funds.find(f => f.isin === isin);
          return (
            <MatrixFundName
              fundName={fund?.shareClassDetailsCode ?? ''}
              isin={fund?.isin ?? ''}
              index={index}
              isFeatured={fund?.isFeatured}
              isLoading={query.isLoading}
              key={isin}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CorrelationMatrix;
