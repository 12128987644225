import { ParsedChartDataSeries } from '@aminsights/shared';
import React, { useState } from 'react';

import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import PremiumDiscountChartV2 from '@/partials/Charts/premium-discount/PremiumDiscountChartV2';
import SharePriceVsNavChartV2 from '@/partials/Charts/share-price-vs-nav/SharePriceVsNavChartV2';

export const InvestmentTrustCharts: React.FCWithChild = () => {
  const { data: state } = useFundByIsInParam();
  useState<ParsedChartDataSeries[]>([]);

  return (
    <div className="investment-details-content">
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 lg:col-span-1">
          <div
            className="p-4 bg-white lg:rounded-lg"
            data-test-id="fundDetailsPerfRiskTabSharePriceNavChart"
          >
            <SharePriceVsNavChartV2
              isins={[state?.fund.shareClassDetails.isin || '']}
              benchmarkId={state?.fund.msBenchmarkId}
            />
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <div
            className="p-4 bg-white lg:rounded-lg"
            data-test-id="fundDetailsPerfRiskTabPremiumDiscountChart"
          >
            <PremiumDiscountChartV2
              isins={
                state?.fund.shareClassDetails.isin
                  ? [state?.fund.shareClassDetails.isin]
                  : []
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
