import { ChartDataSeries } from '@aminsights/contract';
import { ChartColorsWithBrightEffect } from '@aminsights/shared';
import { Area, AreaConfig } from '@ant-design/charts';
import { merge } from 'lodash';
import React, { useRef } from 'react';

import {
  calculateStepTickForYAxis,
  PerformanceCardDetails,
} from '../utils/chart-data';
import { TransparentBrightColorsMap } from '../utils/colors';

export interface BaseChartPropsV2 {
  chartOnly?: boolean;
  considerIsFeatured?: boolean;
  showFundLinks?: boolean;
  emptyState?: {
    title: string;
    subTitle: React.ReactNode;
  };
  onRemoveFund?: (isin: string, benchmark?: boolean) => void;
  resetInvReturnFilterOnChange?: boolean;
  isins: string[];
  benchmarkId?: string;
  featuredIsins?: string[];
  retainSortOrder?: boolean;
  chartColors?: Record<string, string>;
}

export interface PortfolioChartsPropsV2 {
  emptyState?: {
    title: string;
    subTitle: React.ReactNode;
  };
  portfolioId?: string;
}

interface Props {
  config: AreaConfig;

  /**
   * Use with caution, potentially introduced alignment issues.
   */
  shouldYScaleBeChanged?: boolean;
  strokeColor?: string;

  /**
   * This will make other lines transparent and make hovered lines bright,
   * but if this is false all lines are bright. isFeatured lines and isBenchmark
   * lines are always bright
   */
  enableHoverEffects?: boolean;

  onUpdateLegend?: (
    value: React.SetStateAction<PerformanceCardDetails[]>,
  ) => void;
  dataTestId: string;
  scrollable?: boolean;

  tooltipOrder?: string[];
  rightYAxis?: boolean;
}

interface PlotEvent {
  data: {
    color: string;
    data: ChartDataSeries[];
  };
}

const BaseChart: React.FCWithChild<Props> = ({
  config,
  shouldYScaleBeChanged,
  strokeColor,
  enableHoverEffects = false,
  onUpdateLegend,
  dataTestId,
  scrollable = false,

  tooltipOrder,
  rightYAxis = true,
}) => {
  const currentLine = useRef('');

  const getMinMaxYAxisValues = (data: ChartDataSeries[]) => {
    const yAxisValues = data.map(d => d.value);
    yAxisValues.sort((a, b) => a - b);
    return { min: yAxisValues[0], max: yAxisValues[yAxisValues.length - 1] };
  };

  const minMaxYAxisValues = getMinMaxYAxisValues(
    config.data as ChartDataSeries[],
  );
  const yAxisTickInterval = calculateStepTickForYAxis(minMaxYAxisValues);

  const handleUpdateLegend = (id: string, color: string, active?: boolean) => {
    onUpdateLegend?.(prev => {
      const currIndex = prev.findIndex(p => p.id === id);
      return prev.map((p, idx) => {
        if (idx === currIndex) {
          return {
            ...p,
            color,
            isActive: active,
          };
        }
        return p;
      });
    });
  };

  const defaultConfig: Partial<AreaConfig> = {
    xField: 'date',
    yField: 'value',
    localRefresh: false,
    meta: {
      date: {
        range: [0, 1],
      },
    },
    padding: rightYAxis ? [30, 40, 30, 20] : [30, 30, 30, 40],
    appendPadding: [0, 0, 20, 0],
    seriesField: 'id',
    yAxis: {
      position: rightYAxis ? 'right' : 'left',
      tickInterval: yAxisTickInterval,
      label: {
        formatter: (text: string) => `${text}%`,
        offset: rightYAxis ? 6 : 13,
        style: {
          fill: '#545576',
        },
      },
      grid: {
        alignTick: true,
        line: {
          style: {
            stroke: strokeColor,
          },
        },
      },
    },
    xAxis: {
      tickInterval: 1,
      label: {
        offset: 15,
        style: () => {
          return { fill: '#545576' };
        },
      },
      grid: {
        alignTick: true,
        line: {
          style: (_: never) => {
            const stroke = strokeColor;
            return {
              stroke,
              lineDash: [5, 5],
            };
          },
        },
      },
      tickLine: null,
    },
    tooltip: {
      showCrosshairs: true,
      customContent: (title, data) => (
        <div className="flex flex-col gap-y-4">
          <h3 className="text-gray-500 font-bold">{title}</h3>
          <div className="flex flex-col gap-y-3">
            {data
              .sort((a, b) => {
                if (tooltipOrder) {
                  const aIndex = tooltipOrder.indexOf(a.data.id);
                  const bIndex = tooltipOrder.indexOf(b.data.id);
                  if (aIndex === -1 && bIndex === -1) return 0;
                  if (aIndex === -1) return 1;
                  if (bIndex === -1) return -1;
                  return aIndex - bIndex;
                }
                return a.data.value < b.data.value ? 1 : -1;
              })
              .map(d => {
                const isCurrent = currentLine.current === d.data.id;
                const color =
                  isCurrent &&
                  !(d.data.isFeatured || d.data.isBenchmark) &&
                  enableHoverEffects
                    ? TransparentBrightColorsMap[d.color]
                    : d.color;

                return (
                  <div
                    className="flex justify-between items-center gap-x-1"
                    key={`${d.data.id}-${d.data.date}`}
                  >
                    <span
                      style={{ height: 10, width: 10, backgroundColor: color }}
                      className="rounded-full"
                    />
                    <span className="font-bold">
                      {d.data.value.toFixed(1)}%
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      ),
      crosshairs: {
        line: {
          style: {
            fillOpacity: 0.5,
            stroke: '#8C92B1',
            lineWidth: 1,
            lineDash: [4, 5],
            shadowColor: 'black',
            shadowBlur: 25,
            cursor: 'pointer',
          },
        },
      },
    },
    areaStyle: () => ({ fill: 'transparent' }),
    isStack: false,
    autoFit: true,
    legend: false,
    interactions: [
      {
        type: 'element-active',
      },
    ],
    onEvent: (_, event) => {
      const plotEventData = event.data as PlotEvent['data'];
      if (event.type === 'mouseout' && plotEventData) {
        const data = plotEventData.data;
        const color = plotEventData.color;
        const id = data?.[0]?.id;
        const matchingColor = ChartColorsWithBrightEffect.find(
          d => d.bright === color,
        );

        const isAlwaysBright = data?.[0]?.isFeatured || data?.[0]?.isBenchmark;

        if (matchingColor && id) {
          if (enableHoverEffects && !isAlwaysBright) {
            plotEventData.color = matchingColor?.transparent; // Intentional mutation
          } else if (enableHoverEffects || isAlwaysBright) {
            plotEventData.color = matchingColor?.bright;
          } else if (!enableHoverEffects) {
            plotEventData.color = matchingColor?.bright;
          }
          currentLine.current = '';
          handleUpdateLegend(id, plotEventData.color, false);
        }
      } else if (event.type === 'mouseover' && plotEventData) {
        const data = plotEventData.data;
        const color = plotEventData.color;
        const id = data?.[0]?.id;
        const activeColor = TransparentBrightColorsMap[color];
        if (activeColor && id) {
          plotEventData.color = activeColor;
          currentLine.current = id;
          handleUpdateLegend(id, activeColor, true);
        }
      }
    },
  };

  if (defaultConfig.yAxis && shouldYScaleBeChanged) {
    const minChartPoint = minMaxYAxisValues.min;
    const maxChartPoint = minMaxYAxisValues.max;
    defaultConfig.yAxis.min = minChartPoint - 0.1;
    defaultConfig.yAxis.max = maxChartPoint + 0.1;
  }

  const finalConfig = merge(defaultConfig, config);

  return (
    <div
      data-test-id={`${dataTestId}`}
      className={scrollable ? 'overflow-scroll' : ''}
      key={enableHoverEffects?.toString()}
    >
      <Area {...finalConfig} style={scrollable ? { minWidth: '1100px' } : {}} />
    </div>
  );
};

export default React.memo(BaseChart);
