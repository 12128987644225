export const TREATMENT_NAME_CLARITY = 'clarity';
export const TREATMENT_NAME_FUND_DETAILS_ACTIONS = 'fund-details-actions';
export const TREATMENT_NAME_WEEKLY_UPDATE = 'weekly-update';
export const TREATMENT_NAME_SC_FLOW = 'sc-flow';

export enum TreatmentValues {
  On = 'on',
  Off = 'off',
  Control = 'control',
}
