import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as GraphicClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as InfoWarningRed } from '@/assets/svg/icons/icon-warning-red.svg';
import Button from '@/components/Button';

import style from './style.module.less';

type ModalProps = {
  isVisible: boolean;
  toggleModal: () => void;
};

const ShareBenchmarkModal: React.FCWithChild<ModalProps> = ({
  isVisible,
  toggleModal,
}) => {
  return (
    <Modal
      width={400}
      open={isVisible}
      onCancel={toggleModal}
      centered={true}
      title={
        <div className="flex items-center gap-x-2">
          <InfoWarningRed width={28} height={28} />
          <h2 className="font-bold text-xl">Custom benchmark not shared</h2>
        </div>
      }
      className="action-modal"
      footer={[
        <Button
          className={cx(style['share-benchmark-modal__close'], 'mb-0')}
          type="link"
          key="secondary"
          onClick={toggleModal}
        >
          Close
        </Button>,
      ]}
      closeIcon={<GraphicClose />}
    >
      <div className={style['share-benchmark-modal__container']}>
        <div className="flex-1 flex flex-col gap-y-4">
          <div className={style['share-benchmark-modal__description']}>
            The selected benchmark is only visible to you. Update this in{' '}
            <Link
              to="/settings/custom-benchmarks"
              className={style['share-benchmark-modal__link']}
            >
              settings
            </Link>{' '}
            before sharing this portfolio.
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareBenchmarkModal;
