import { SignificantChangesItem } from '@aminsights/contract';
import {
  DISPLAY_DATE_FORMAT,
  EMPTY_DATA_POINT,
  getChangeTypeLabel,
} from '@aminsights/shared';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import useIsMobileView from '@/utils/useIsMobileView';

import GetChangeColumn from './GetChangeColumn';
import SignificantChangesColumn from './SignificantChangesFirstColumn';

const useSignificantChangesColumns = (): IDataTableColumns[] => {
  const isMobile = useIsMobileView();
  return [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      sortKey: 'fundName',
      render: (item: SignificantChangesItem): React.ReactNode => (
        <SignificantChangesColumn data={item} />
      ),
      renderType: 'custom',
      align: 'between',
      width: isMobile ? 192 : 246,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: <span className="whitespace-normal text-start">Type</span>,
      dataTestIdSuffix: 'Type',
      render: (item: SignificantChangesItem): React.ReactElement => {
        const changeType = getChangeTypeLabel(item.changeType);

        return <>{!item.changeType ? EMPTY_DATA_POINT : `${changeType}`}</>;
      },
      renderType: 'custom',
      align: 'between',
      width: isMobile ? 120 : undefined,
      sortKey: 'changeType',
    },
    {
      title: <span className="whitespace-normal text-start">Change</span>,
      dataTestIdSuffix: 'Change',
      render: (item: SignificantChangesItem): React.ReactElement => {
        return (
          <>
            <GetChangeColumn data={item} length={35} />
          </>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: isMobile ? 120 : undefined,
      sortKey: 'pctChange',
    },
    {
      title: <span className="whitespace-normal text-start">Date</span>,
      dataTestIdSuffix: 'Date',
      render: (item: SignificantChangesItem): React.ReactElement => {
        return (
          <>
            {!item.updatedDate
              ? EMPTY_DATA_POINT
              : `${dayjs(item.updatedDate).format(DISPLAY_DATE_FORMAT) ?? ''}`}
          </>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: isMobile ? 120 : undefined,
      sortKey: 'updatedDate',
    },
  ];
};

export default useSignificantChangesColumns;
