// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sNfxNOJovIIWD3cjNhYN .ant-collapse-borderless {\n    background-color: transparent\n}\n.sNfxNOJovIIWD3cjNhYN .ant-collapse-item {\n    border-style: solid;\n    --tw-border-opacity: 1;\n    border-color: rgb(234 234 234 / var(--tw-border-opacity))\n}\n.sNfxNOJovIIWD3cjNhYN .ant-collapse-item .ant-collapse-header {\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 700;\n    --tw-text-opacity: 1;\n    color: rgb(84 85 118 / var(--tw-text-opacity))\n}\n.sNfxNOJovIIWD3cjNhYN .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {\n    fill: currentColor;\n    --tw-text-opacity: 1;\n    color: rgb(140 146 177 / var(--tw-text-opacity))\n}\n.sNfxNOJovIIWD3cjNhYN .ant-collapse-item .ant-collapse-content-box {\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    --tw-text-opacity: 1;\n    color: rgb(84 85 118 / var(--tw-text-opacity))\n}\n", "",{"version":3,"sources":["webpack://./src/components/Accordion/style.module.less"],"names":[],"mappings":"AAGM;IAAA;AAAA;AAGA;IAAA,mBAAA;IAAA,sBAAA;IAAA;AAAA;AAEE;IAAA,iBAAA;IAAA,oBAAA;IAAA,mBAAA;IAAA,oBAAA;IAAA,gBAAA;IAAA,oBAAA;IAAA;AAAA;AAEE;IAAA,kBAAA;IAAA,oBAAA;IAAA;AAAA;AAIF;IAAA,mBAAA;IAAA,oBAAA;IAAA,oBAAA;IAAA;AAAA","sourcesContent":[".custom-accordion {\n  :global {\n    .ant-collapse-borderless {\n      @apply bg-transparent;\n    }\n    .ant-collapse-item {\n      @apply border-solid border-grey-light-50;\n      .ant-collapse-header {\n        @apply py-4 text-sm font-bold text-neutral;\n        .ant-collapse-arrow {\n          @apply fill-current text-neutral-100;\n        }\n      }\n      .ant-collapse-content-box {\n        @apply text-sm text-neutral;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-accordion": "sNfxNOJovIIWD3cjNhYN"
};
export default ___CSS_LOADER_EXPORT___;
