import './style.less';

import { SignificantChangesItem } from '@aminsights/contract';
import { APP_ROUTE_ANNOUNCEMENTS } from '@aminsights/shared';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button, Tabs } from '@/components';
import { ComingSoonBadge } from '@/components/Badges/ComingSoon';
import { BREAKPOINTS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { MarketDataChart } from '@/pages/app/Dashboard/Charts/MarketData';
import SignificantChangesModal from '@/partials/SignificantChanges/SignificantChangesContentModal';
import Summary from '@/partials/Summary';

import BasePageWithMetadata from '../BasePageWithMetadata';
import { Announcements, AnnouncementsMobile } from './Announcements';
import { announcementsData } from './data';
import FundPerformance from './FundPerformance';
import { ViewMode } from './SignificantChanges/index';
import SignificantChangesWidget from './SignificantChanges/index';
import style from './style.module.less';

const Dashboard: React.FCWithChild = () => {
  const { app } = useAppContext();
  const [selectedSignificantChange, setSelectedSignificantChange] =
    useState<SignificantChangesItem | null>();
  const showAnnouncements = false;

  const mobileItems = [
    {
      label: 'Significant Changes',
      key: 'Significant',
      children: (
        <SignificantChangesWidget
          onSignificantChangeClicked={sg => setSelectedSignificantChange(sg)}
          mode={ViewMode.mobile}
        />
      ),
    },
  ];

  if (showAnnouncements) {
    mobileItems.push({
      label: 'Announcements',
      key: 'Announcements',
      children: (
        <>
          <AnnouncementsMobile data={announcementsData} />
          {/* Temporarily commented out until annoucements are implemented */}
          <button
            className="w-full text-center bg-white border-t border-b-0 border-l-0 border-r-0 border-solid border-grey-light-50"
            type="button"
          >
            <p className="py-6 font-semibold font-sm text-primary">
              {/* Uncommented for Coming Soon Badge */}
              <ComingSoonBadge />
            </p>
          </button>
        </>
      ),
    });
  }

  return (
    <BasePageWithMetadata title={'Dashboard'}>
      <section className="section-dashboard">
        <div className="p-4 pl-0">
          <Summary />
        </div>
        <Modal
          title="Significant Changes"
          className="information-modal [&_.ant-modal-body]:pb-10 [&_.ant-modal-header]:shadow-sm"
          open={!!selectedSignificantChange}
          onClose={() => setSelectedSignificantChange(null)}
          footer={null}
          onCancel={() => setSelectedSignificantChange(null)}
        >
          {selectedSignificantChange && (
            <SignificantChangesModal
              data={selectedSignificantChange}
              toggleModal={() => setSelectedSignificantChange(null)}
            />
          )}
        </Modal>

        <div className="flex flex-col-reverse flex-wrap lg:flex-row lg:px-4">
          <div className="w-full lg:w-4/12">
            <SignificantChangesWidget
              onSignificantChangeClicked={sg =>
                setSelectedSignificantChange(sg)
              }
              mode={ViewMode.desktop}
            />
            <div className="px-4 pt-4 mb-6 bg-white lg:rounded-lg md:pt-0 pb-9">
              <MarketDataChart />
            </div>
            {(app?.windowWidth || 0) < BREAKPOINTS.LG && showAnnouncements && (
              <div className="mb-6 section-dashboard__card">
                <div className="flex items-center justify-between pt-4 pb-4 pl-4 pr-4">
                  <h5>Announcements</h5>
                  <ComingSoonBadge />
                  <Link to={APP_ROUTE_ANNOUNCEMENTS}>
                    <Button size="small" type="link" className="font-semibold">
                      View All
                    </Button>
                  </Link>
                </div>
                <Announcements data={announcementsData} />
              </div>
            )}
          </div>
          {(app?.windowWidth || 0) < BREAKPOINTS.MD ? (
            <div className="w-full mb-6 bg-white lg:rounded-lg">
              <Tabs
                className={style['mobile-tabs']}
                tabBarExtraContent={<ComingSoonBadge />}
                items={mobileItems}
              />
            </div>
          ) : (
            ''
          )}

          <div className="w-full lg:w-8/12 lg:pl-4">
            <FundPerformance />

            {(app?.windowWidth || 0) >= BREAKPOINTS.LG && showAnnouncements ? (
              <div className="mb-6 section-dashboard__card">
                <div className="flex items-center justify-between pt-4 pb-4 pl-4 pr-4">
                  <h5>Announcements</h5>
                  <ComingSoonBadge />
                  {/* Temporarily commented out until annoucements are implemented */}
                  {/* <Link to={APP_ROUTE_ANNOUNCEMENTS}>
                  <Button size="small" type="link" className="font-semibold">
                    View All
                  </Button>
                </Link> */}
                </div>
                <Announcements data={announcementsData} />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
    </BasePageWithMetadata>
  );
};

export default Dashboard;
