import {
  Fund,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import { LIMIT_FUNDS_PER_BUCKET } from '@aminsights/shared';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as IconPlus } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as IconDelete } from '@/assets/svg/icons/icon-delete.svg';
import { ReactComponent as IconWarning } from '@/assets/svg/icons/icon-warning.svg';
import { Button, DataTable } from '@/components';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import AllFundsAssignedModal from '@/pages/app/Watchlist/Buckets/components/ImportFunds/AllFundsAssignedModal';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import WatchlistModal from '@/partials/Modal/WatchlistModal';
import { sortArray } from '@/utils/array';

import {
  IImportedFundsData,
  ImportedFundsDataTableColumns,
} from './ImportedFundsDataTableColumns';
import style from './style.module.less';

const AssignFundsToPortfolioComponent: React.FCWithChild<{
  importedFundsList: Fund[];
  areISINsUnavailable?: boolean;
  onModalOpen?: (isOpen: boolean) => void;
}> = ({ importedFundsList, areISINsUnavailable, onModalOpen }) => {
  const currentWatchlist = useCurrentWatchlist();
  const buckets = currentWatchlist.data?.buckets;
  const [fundsTableList, setFundsTableList] = useState<IImportedFundsData[]>(
    [],
  );
  const [allISINs, setAllISINs] = useState<string[]>([]);
  const [checkedDeletedRows, setCheckedDeletedRows] = useState<string[]>([]);
  const [isAddToWatchlistModalOpen, setIsAddToWatchlistModalOpen] =
    useState(false);
  const [areAllFundsAssignedModalOpen, setAreAllFundsAssignedModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showBucketListOnly, setShowBucketListOnly] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] =
    useState<PageQueryParametersSortDirectionEnum>();

  const {
    checkedRows,
    setCheckedRows,
    isAllCheckboxChecked,
    setIsAllCheckboxChecked,
    onCheckedRow,
    setCheckedData,
  } = useOnCheckedRow<IImportedFundsData>(fundsTableList.length || 0);

  const handleAddWatchlistModalOpen = () => {
    setIsAddToWatchlistModalOpen(prev => !prev);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(prev => !prev);
  };

  useEffect(() => {
    let importedFunds = importedFundsList;

    if (checkedDeletedRows && checkedDeletedRows.length) {
      importedFunds = importedFundsList.filter(
        fund => !checkedDeletedRows.includes(fund.shareClassDetails.isin),
      );
    }

    if (importedFunds) {
      setIsLoading(true);

      const transformedFunds = importedFunds.map(fund => {
        const isWatching = buckets?.some(b =>
          b.funds?.some(f => f.isin === fund.shareClassDetails.isin),
        );
        const isChecked = checkedRows.includes(fund.shareClassDetails.isin);
        return {
          key: fund.shareClassDetails.isin,
          fundName: fund.fundName,
          isin: fund.shareClassDetails.isin,
          category: fund.msCategoryDefinition,
          isWatching,
          isChecked,
        };
      });

      const sorted = sortArray(
        transformedFunds,
        'fundName',
        sortOrder || PageQueryParametersSortDirectionEnum.Asc,
      );

      setFundsTableList(sorted.filter(fund => !fund.isWatching));

      if (transformedFunds && transformedFunds.length > 0) {
        setIsLoading(false);
      }
    }
  }, [importedFundsList, buckets, checkedDeletedRows, checkedRows, sortOrder]);

  useEffect(() => {
    if (isSuccess) {
      setCheckedRows([]);
      setIsAllCheckboxChecked(false);
      setFundsTableList(
        fundsTableList.map(fund => ({ ...fund, isChecked: false })),
      );
      setIsSuccess(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    const allItems = fundsTableList.slice(0, fundsTableList.length);
    const isinValues = allItems.map(item => item.isin);
    setAllISINs(isinValues);

    const onCheckedData = () => {
      setCheckedData(fundsTableList);
    };

    onCheckedData();

    setAreAllFundsAssignedModalOpen(!isLoading && !fundsTableList.length);
    if (!fundsTableList.length) {
      setIsLoading(false);
    }
  }, [fundsTableList, isLoading]);

  useEffect(() => {
    if (
      checkedRows.length > 0 &&
      checkedRows.length === fundsTableList.length
    ) {
      setIsAllCheckboxChecked(true);
    }
  }, [checkedRows, fundsTableList]);

  const handleOnSort = (
    key: keyof IImportedFundsData,
    direction: PageQueryParametersSortDirectionEnum,
  ) => {
    const sorted = sortArray(fundsTableList, key, sortOrder || direction);
    setFundsTableList(sorted);
    setSortOrder(direction);
  };

  const onDeleteFunds = () => {
    return new Promise<void>(resolve => {
      setFundsTableList(
        fundsTableList.filter(fund => !checkedRows.includes(fund.isin)),
      );
      setCheckedDeletedRows([...checkedDeletedRows, ...checkedRows]);
      setCheckedRows([]);
      setIsAllCheckboxChecked(false);
      resolve();
    });
  };

  return (
    <div className={style['imported-funds']}>
      {areISINsUnavailable && (
        <div className={style['warning-alert']}>
          <div className="flex items-center">
            <IconWarning width={18} height={18} fill="#CF3B31" />
            <div className="pl-3 font-semibold">ISIN(s) not found</div>
          </div>
          <div className="flex items-center justify-between">
            <div className={cx(style['description'], 'pl-7')}>
              We found ISIN(s) that are not in our database.
            </div>
            <Button
              type="default"
              className={style['view-details-btn']}
              onClick={() => onModalOpen?.(true)}
            >
              View Details
            </Button>
          </div>
        </div>
      )}
      <h2 className={style['title']}>Assign funds/trusts to a bucket</h2>
      <p className={style['description']}>
        To proceed, you must first assign all of your funds/trust to a bucket.
        Buckets are used to categorise your funds/trusts so you can easily view
        and compare them. You can only assign a maximum of 11 funds/trusts per
        bucket.
      </p>

      <div className={style['imported-funds-wrapper']}>
        <div className="flex items-center justify-between w-full pb-2">
          <div>
            <p className={style['fund-count']}>
              {fundsTableList.length} funds/trusts
            </p>
            <p>Please select a maximum of 11 funds/trusts</p>
          </div>
          {!checkedRows.length ? (
            <a
              onClick={() => {
                setIsAddToWatchlistModalOpen(true);
                setShowBucketListOnly(true);
              }}
            >
              View Buckets
            </a>
          ) : (
            <div className="flex">
              <Button
                type="default"
                className={style['delete-btn']}
                onClick={() => {
                  setIsDeleteModalOpen(true);
                }}
              >
                <IconDelete className="icon text-md" />
                <p className="hidden ml-2 text-sm font-medium md:block">
                  Delete
                </p>
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setIsAddToWatchlistModalOpen(true);
                  setShowBucketListOnly(false);
                }}
                disabled={checkedRows.length > LIMIT_FUNDS_PER_BUCKET}
              >
                <IconPlus className="icon text-md" />
                <p className="hidden ml-2 text-sm font-medium md:block">
                  Assign to Bucket
                </p>
              </Button>
            </div>
          )}
        </div>
        <DataTable
          uniqueKey="isin"
          loading={isLoading}
          columns={ImportedFundsDataTableColumns(
            onCheckedRow,
            allISINs,
            isAllCheckboxChecked,
          )}
          data={fundsTableList}
          onSort={(
            key: string,
            direction: PageQueryParametersSortDirectionEnum,
          ) => handleOnSort(key as keyof IImportedFundsData, direction)}
        />
      </div>
      <WatchlistModal
        isins={checkedRows}
        fundName={''}
        isVisible={isAddToWatchlistModalOpen}
        toggleModal={handleAddWatchlistModalOpen}
        showBucketListOnly={showBucketListOnly}
        isSuccess={setIsSuccess}
      />
      <AllFundsAssignedModal
        isVisible={areAllFundsAssignedModalOpen && !areISINsUnavailable}
      />
      <ConfirmationModalDanger
        modalInfo={{
          title: 'Delete Funds/Trusts?',
          description: `${
            fundsTableList.filter(fund => checkedRows.includes(fund.isin))
              .length
          } funds/trusts selected will be deleted`,
          primaryActionLabel: 'Delete',
          succesMessage: 'Successfully delete funds/trusts',
          errorMessage: '',
        }}
        isVisible={isDeleteModalOpen}
        toggleModal={handleDeleteModalOpen}
        onConfirm={onDeleteFunds}
      />
    </div>
  );
};

export default AssignFundsToPortfolioComponent;
