import './style.less';

import { ChangeTypeLabels } from '@aminsights/shared';
import { ChangeTypeEnum } from '@aminsights/shared';
import React, { useMemo } from 'react';

import { ReactComponent as ClearFilterIcon } from '@/assets/svg/icons/icon-clear-red.svg';
import { Dropdown } from '@/components/Dropdown';
import SearchDropdown from '@/components/Search/SearchDropdown';
import { BREAKPOINTS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import {
  SignificantChangesParameters,
  SignificantChangesState,
} from '@/pages/app/SignificantChanges/types';
import { dateDropDownData } from '@/partials/SignificantChanges/data';

interface ISignificantChangesFilter {
  state: SignificantChangesState;
  onParamsChange: (params: Partial<SignificantChangesParameters>) => void;
  onClear: () => void;
  anyFiltersSelected?: boolean;
}

const SignificantChangesFilter: React.FCWithChild<
  ISignificantChangesFilter
> = ({ state, onParamsChange, onClear, anyFiltersSelected }) => {
  const { state: featureSwitchState } = useFeatureSwitchContext();

  const { app } = useAppContext();
  const changeTypes = useMemo(() => {
    const result = Object.entries(ChangeTypeLabels)
      .filter(([key, _value]) => {
        if (key === ChangeTypeEnum.Flow) {
          return featureSwitchState.isScFlowTreatmentEnabled;
        }
        return true;
      })
      .map(([key, value]) => ({
        label: value,
        checked:
          (state.significantChangesParameters.changeType ?? []).indexOf(key) !==
          -1,
      }));
    return result;
  }, [state.significantChangesParameters.changeType]);

  return (
    <div className="flex gap-2 search-dropdown">
      <div
        className="w-full"
        data-test-id="fundDetailsSignificantChangesTabTypeDropdown"
      >
        <SearchDropdown
          placement="bottomLeft"
          items={changeTypes}
          placeholder="Type"
          onChange={(selectedValues: string[]) => {
            const typeMap = selectedValues.map(selectedValue => {
              return (
                Object.entries(ChangeTypeLabels).find(
                  ([_key, value]) => value === selectedValue,
                )?.[0] || ''
              );
            });
            onParamsChange({ changeType: typeMap });
          }}
          selectedFilter={state.significantChangesParameters.changeType?.length}
          showSelectAll
        />
      </div>
      <div
        className="w-full"
        data-test-id="fundDetailsSignificantChangesTabDateDropdown"
      >
        <Dropdown
          label={
            dateDropDownData.find(
              date => date.value === state.significantChangesParameters.period,
            )?.label || 'Date'
          }
          items={dateDropDownData}
          value={state.significantChangesParameters.period}
          onSelect={(value: string) => {
            onParamsChange({ period: value });
          }}
        />
      </div>
      {anyFiltersSelected || state.significantChangesParameters.term !== '' ? (
        <div className="w-full py-1 pl-1 whitespace-nowrap">
          <div
            className="clear-filters"
            onClick={() => {
              onClear();
            }}
            aria-hidden="true"
            id="clear-filter"
            data-test-id={'clearFilter'}
          >
            {(app?.windowWidth || 0) < BREAKPOINTS.XL ? (
              <ClearFilterIcon />
            ) : (
              'Clear filters'
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SignificantChangesFilter;
