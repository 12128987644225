import {
  Fund,
  PageQueryParametersSortDirectionEnum,
  ShareClassDetailsResponseData,
} from '@aminsights/contract';
import {
  buildFundDetailsPath,
  EMPTY_DATA_POINT,
  LIMIT_FUNDS_FOR_CHARTING,
} from '@aminsights/shared';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { StarFilled } from '@ant-design/icons';
import cx from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { DataTable } from '@/components';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import TableCheckbox, {
  TableCheckboxHeader,
} from '@/pages/app/Explore/components/TableCheckbox';
import { sortArray } from '@/utils/array';

import TablesActionToast from '../Toast/TablesActionToast';
import { IShareClassDetailsResponseData } from './data';
import style from './style.module.less';

type ShareClassTableProps = {
  isLoading?: boolean;
  data?: Array<ShareClassDetailsResponseData>;
  onAddToBucket?: (isins: string[]) => void;
  noCheckbox?: true | false;
  defaultChecked?: string[];
};

type GetColumnsProps =
  | {
      onCheck: (isins: string[]) => void;
      allItemsChecked: boolean;
      topISINs: string[];
      itemsChecked: string[];
      noCheckbox: boolean;
    }
  | {
      noCheckbox: boolean;
      onCheck: never;
      allItemsChecked: never;
      topISINs: never;
      itemsChecked: never;
    };

const getColumns = ({
  onCheck,
  allItemsChecked,
  topISINs,
  itemsChecked,
  noCheckbox,
}: GetColumnsProps): Array<IDataTableColumns> => [
  ...(!noCheckbox
    ? [
        {
          title: '',
          width: 45,
          headerElements: (): React.ReactNode => (
            <TableCheckboxHeader
              onClick={onCheck}
              topISINs={topISINs}
              checked={allItemsChecked}
            />
          ),
          renderType: 'text' as const,
          render: (fund: IShareClassDetailsResponseData): React.ReactNode => (
            <TableCheckbox
              fund={fund}
              onClick={onCheck}
              selectedItems={itemsChecked}
              dataTableRenderedAt={DataTableRenderedAt.ShareClasses}
              showPrimaryShareClassIndicator={true}
            />
          ),
        },
      ]
    : [
        {
          title: '',
          renderType: 'custom' as const,
          render: (fund: Fund) => (
            <div className="flex relative justify-center items-center">
              <StarFilled
                className={cx(
                  'text-[#0072E6] w-3 h-3',
                  fund?.primaryShareClass ? 'opacity-100' : 'opacity-0',
                )}
              />
            </div>
          ),
          width: 16,
        },
      ]),
  {
    title: 'Share Class',
    render: (fund: IShareClassDetailsResponseData) =>
      fund.isin && (
        <div className="flex flex-col">
          <Link to={buildFundDetailsPath(fund.isin)} className="font-bold">
            {fund.code}
          </Link>
          {fund.primaryShareClass && (
            <div className={cx(style['sub-label'], 'hidden md:block')}>
              <span className={style.tag}> Primary Share Class </span>
            </div>
          )}
        </div>
      ),
    renderType: 'text',
    sortKey: 'code',
    isColumnFixed: true,
  },
  {
    title: 'ISIN',
    sortKey: 'isin',
    render: (fund: IShareClassDetailsResponseData) => (
      <div className="custom-table__first-col">{fund.isin}</div>
    ),
    renderType: 'text',
    defaultSortOrder: 'descend',
  },
  {
    title: 'OCF',
    sortKey: 'ocfPct',
    render: (fund: IShareClassDetailsResponseData) => (
      <div className="custom-table__first-col">
        {fund.ocfPct
          ? `${fund.ocfPct && parseFloat(fund.ocfPct.toString()).toFixed(2)}%`
          : EMPTY_DATA_POINT}
      </div>
    ),
    renderType: 'number',
    defaultSortOrder: 'descend',
  },
  {
    title: 'Launch Date',
    render: (fund: IShareClassDetailsResponseData) => (
      <div className="custom-table__first-col">
        {fund.inceptionDate &&
          dayjs(fund.inceptionDate).format(DISPLAY_DATE_FORMAT)}
      </div>
    ),
    renderType: 'text',
    sortKey: 'inceptionDate',
  },
];

const ShareClassTable: React.FCWithChild<ShareClassTableProps> = ({
  data,
  isLoading,
  onAddToBucket,
  noCheckbox = true,
  defaultChecked,
}) => {
  const currentWatchlistQuery = useCurrentWatchlist();

  const [shareClasses, setShareClasses] =
    useState<IShareClassDetailsResponseData[]>();

  const topISINs = useMemo(() => {
    const firstItems = (shareClasses || []).slice(0, LIMIT_FUNDS_FOR_CHARTING);
    return firstItems.map(item => item.isin).filter(Boolean) as string[];
  }, [shareClasses, currentWatchlistQuery.data]);

  const {
    checkedRows,
    setCheckedRows,
    isAllCheckboxChecked,
    setIsAllCheckboxChecked,
    onCheckedRow,
  } = useOnCheckedRow<IShareClassDetailsResponseData>(topISINs?.length || 0);

  useEffect(() => {
    const shareClassData = data?.map((value, index) => {
      return {
        key: index.toString(),
        code: value.shareClassDetails?.code,
        inceptionDate: value.shareClassDetails?.inceptionDate,
        classCategoryBenchmarkName:
          value.shareClassDetails?.classCategoryBenchmarkName,
        isin: value.shareClassDetails?.isin,
        ocfPct: value.ocfPct,
        primaryShareClass: value.primaryShareClass,
      };
    });
    //this moves the primary share class to the top and sorts the rest by ocf
    const primaryShareClass = shareClassData?.find(
      item => item.primaryShareClass === true,
    );
    const noPrimaryShareClass = shareClassData?.filter(
      item => item.primaryShareClass === false,
    );
    const sorted = sortArray(
      noPrimaryShareClass || [],
      'ocfPct',
      PageQueryParametersSortDirectionEnum.Asc,
    );
    if (primaryShareClass) {
      sorted.unshift(primaryShareClass);
    }
    setShareClasses(sorted);
  }, [data]);

  const handleOnSort = (
    key: keyof IShareClassDetailsResponseData,
    direction: PageQueryParametersSortDirectionEnum,
  ) => {
    const sorted = sortArray(shareClasses || [], key, direction);
    setShareClasses(sorted);
  };

  useEffect(() => {
    if (defaultChecked?.length) {
      setCheckedRows(defaultChecked);
    }
  }, [defaultChecked]);

  return (
    <>
      <DataTable
        uniqueKey="isin"
        columns={getColumns({
          onCheck: onCheckedRow,
          allItemsChecked: isAllCheckboxChecked,
          itemsChecked: checkedRows,
          topISINs,
          noCheckbox,
        })}
        data={shareClasses}
        loading={isLoading}
        onSort={(
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) =>
          handleOnSort(key as keyof IShareClassDetailsResponseData, direction)
        }
      />
      {checkedRows.length > 0 && (
        <TablesActionToast
          isins={checkedRows}
          count={checkedRows.length}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
          onClickAdd={onAddToBucket}
          dataTableRenderedAt={DataTableRenderedAt.ShareClasses}
        />
      )}
    </>
  );
};

export default ShareClassTable;
