import { SignificantChangesThreshold } from '@aminsights/contract';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { Button } from '@/components';
import { Dropdown } from '@/components/Dropdown';
import Tooltip from '@/components/Tooltip';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { useFeatureSwitchContext } from '@/context/FeatureSwitchContext';
import useGetSignificantChangesThreshold from '@/hooks/query-hooks/significant-changes-hooks/useGetSignificantChangesThreshold';
import useSaveSignificantChangesThreshold from '@/hooks/query-hooks/significant-changes-hooks/useSaveSignificantChangesThreshold';
import { SIGNIFICANT_CHANGES } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import queryClient from '@/queryClient';

import significantValues from './significantValues';
import style from './style.module.less';

const SignificantChanges: React.FCWithChild = () => {
  const { user } = useAuth0();
  const { dispatch: dispatchApp } = useAppContext();
  const { state: featureSwitchState } = useFeatureSwitchContext();

  const { mutateAsync: saveSignificantChangesThreshold, isLoading } =
    useSaveSignificantChangesThreshold({
      onSuccess: () => {
        queryClient.invalidateQueries([SIGNIFICANT_CHANGES]);
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: 'Significant Changes settings updated.' },
        });
      },
      onError: () => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: 'Something went wrong.',
        });
      },
    });
  const { data: significantChangesThresholdResponse } =
    useGetSignificantChangesThreshold();

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, isValid },
    setValue,
    reset,
  } = useForm<{ significantChangesThreshold: SignificantChangesThreshold }>({
    mode: 'all',
  });

  const setSignificantChangesThresholdValue = () => {
    if (significantChangesThresholdResponse) {
      setValue(
        'significantChangesThreshold',
        significantChangesThresholdResponse,
      );
    }
  };

  useEffect(setSignificantChangesThresholdValue, [
    significantChangesThresholdResponse,
  ]);

  const onFormSubmit = async (values: {
    significantChangesThreshold: SignificantChangesThreshold;
  }) => {
    if (!user?.sub || !isValid) {
      return;
    }

    await saveSignificantChangesThreshold({
      ...values.significantChangesThreshold,
      userId: user?.sub,
    });
    reset({
      significantChangesThreshold: values.significantChangesThreshold,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col w-full md:w-1/2">
            <div className="mb-4 pr-0 md:pr-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Fund Size</p>
                <Tooltip
                  title="+/-25% means you will be alerted if Fund Size moves from e.g. £500m to >£625m."
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                  arrow={{ pointAtCenter: true }}
                  align={{ offset: [5, -15] }}
                  className="!w-6 !h-6"
                >
                  <InfoIcon className="text-neutral text-base hover:text-neutral-100" />
                </Tooltip>
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.fundSizeThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    rounded
                    label="Select value"
                    onSelect={(selectedValue: number) =>
                      onChange(selectedValue)
                    }
                    items={significantValues.default}
                    defaultValue={value}
                    dataTestId="fundSizeThresholdDropdown"
                  />
                )}
              />
            </div>

            <div className="mb-4 pr-0 md:pr-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">OCF</p>
                <Tooltip
                  title="+/-0.5% means you will be alerted if the OCF moved from e.g. 1.1% to >1.6%"
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                  arrow={{ pointAtCenter: true }}
                  align={{ offset: [5, -15] }}
                  className="!w-6 !h-6"
                >
                  <InfoIcon className="text-neutral text-base hover:text-neutral-100" />
                </Tooltip>
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.ocfThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    rounded
                    label="Select value"
                    onSelect={(selectedValue: number) =>
                      onChange(selectedValue)
                    }
                    items={significantValues.ocf}
                    defaultValue={value}
                    dataTestId="ocfThresholdDropdown"
                  />
                )}
              />
            </div>

            <div className="mb-4 pr-0 md:pr-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Premium/Discount</p>
                <Tooltip
                  title="+/-4% means you will be alerted if the Premium/Discount moved from e.g. -3% to -7%"
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                  arrow={{ pointAtCenter: true }}
                  align={{ offset: [5, -15] }}
                  className="!w-6 !h-6"
                >
                  <InfoIcon className="text-neutral text-base hover:text-neutral-100" />
                </Tooltip>
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.premiumDiscountThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    rounded
                    label="Select value"
                    onSelect={(selectedValue: number) =>
                      onChange(selectedValue)
                    }
                    items={significantValues.premiumDiscount}
                    defaultValue={value}
                    dataTestId="premiumDiscountThresholdDropdown"
                  />
                )}
              />
            </div>

            <div className="mb-4 pr-0 md:pr-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Modified Duration</p>
                <Tooltip
                  title="+/-25% means you will be alerted if Modified Duration moves from e.g. 2 to > 2.5"
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                  arrow={{ pointAtCenter: true }}
                  align={{ offset: [5, -15] }}
                  className="!w-6 !h-6"
                >
                  <InfoIcon className="text-neutral text-base hover:text-neutral-100" />
                </Tooltip>
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.modifiedDurationThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    rounded
                    label="Select value"
                    onSelect={(selectedValue: number) =>
                      onChange(selectedValue)
                    }
                    items={significantValues.default}
                    defaultValue={value}
                    dataTestId="durationThresholdDropdown"
                  />
                )}
              />
            </div>
          </div>

          <div className="flex flex-col w-full md:w-1/2">
            {featureSwitchState.isScFlowTreatmentEnabled && (
              <div className="mb-4 pl-0 md:pl-6">
                <div className="flex items-center mb-2 gap-x-2">
                  <p className="text-neutral">Flow</p>
                  <Tooltip
                    title="+/-25% means you will be alerted if Flow moves from e.g. 2 to > 2.5"
                    placement="rightTop"
                    color="#3E414B"
                    overlayClassName={style['tooltip-rounded']}
                    arrow={{ pointAtCenter: true }}
                    align={{ offset: [5, -15] }}
                    className="!w-6 !h-6"
                  >
                    <InfoIcon className="text-neutral text-base hover:text-neutral-100" />
                  </Tooltip>
                </div>
                <Controller
                  control={control}
                  name="significantChangesThreshold.flowThreshold"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Dropdown
                      rounded
                      label="Select value"
                      onSelect={(selectedValue: number) =>
                        onChange(selectedValue)
                      }
                      items={significantValues.default}
                      defaultValue={value}
                      dataTestId="flowThresholdDropdown"
                    />
                  )}
                />
              </div>
            )}

            <div className="mb-4 pl-0 md:pl-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Holdings</p>
                <Tooltip
                  title="+/-25% means you will be alerted if Holdings change from e.g. 50 to >63."
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                  arrow={{ pointAtCenter: true }}
                  align={{ offset: [5, -15] }}
                  className="!w-6 !h-6"
                >
                  <InfoIcon className="text-neutral text-base hover:text-neutral-100" />
                </Tooltip>
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.holdingsThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    rounded
                    label="Select value"
                    onSelect={(selectedValue: number) =>
                      onChange(selectedValue)
                    }
                    items={significantValues.default}
                    defaultValue={value}
                    dataTestId="holdingsThresholdDropdown"
                  />
                )}
              />
            </div>

            <div className="mb-4 pl-0 md:pl-6">
              <div className="flex items-center mb-2 gap-x-2">
                <p className="text-neutral">Yield</p>
                <Tooltip
                  title="+/-2% means you will be alerted if the Yield moved from e.g. 3.1% to >5.1%"
                  placement="rightTop"
                  color="#3E414B"
                  overlayClassName={style['tooltip-rounded']}
                  arrow={{ pointAtCenter: true }}
                  align={{ offset: [5, -15] }}
                  className="!w-6 !h-6"
                >
                  <InfoIcon className="text-neutral text-base hover:text-neutral-100" />
                </Tooltip>
              </div>
              <Controller
                control={control}
                name="significantChangesThreshold.yieldThreshold"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    rounded
                    label="Select value"
                    onSelect={(selectedValue: number) =>
                      onChange(selectedValue)
                    }
                    items={significantValues.yield}
                    defaultValue={value}
                    dataTestId="yieldThresholdDropdown"
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <Button
            disabled={
              !isValid ||
              isLoading ||
              !isDirty ||
              Object.keys(errors).length !== 0
            }
            className="submit-btn"
            type="primary"
            htmlType="submit"
            data-test-id="saveSignificantChangesButton"
          >
            {isLoading ? 'Please wait...' : 'Save Changes'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default SignificantChanges;
