import {
  AssetAllocationItemTypeEnum,
  Bucket,
  Fund,
} from '@aminsights/contract';
import {
  COLOR_MAXIMUM_FEATURED_TABLE_CELL,
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_FEATURED_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  ICalculatedExtremumsFunds,
  LIMIT_FUNDS_FOR_CHARTING,
} from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';
import { ReactNode } from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { COLOR_FUND_FEATURED } from '@/constants/colors';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { formatPortfolioUpdateDate } from '@/utils/formatPortfolioUpdateDate';
import { roundOrEmpty } from '@/utils/roundOrEmpty';
import useIsMobileView from '@/utils/useIsMobileView';

type WatchlistAssetAllocationColumnsProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  currentBucket: Bucket | undefined;
  onSelectAll: (isChecked: boolean) => void;
  assetAllocationWithExtremums?: ICalculatedExtremumsFunds<AssetAllocationItemTypeEnum>[];
};

const generateAssetAllocationColumn = (
  key: AssetAllocationItemTypeEnum,
  assetAllocationWithExtremums?: ICalculatedExtremumsFunds<AssetAllocationItemTypeEnum>[],
  currentBucket?: Bucket | undefined,
): IDataTableColumns => {
  return {
    title: key,
    sortKey: `assetAllocation.${key}`,
    backgroundColor: (item: Fund) => {
      const assetAllocationWithExtremumsForType =
        assetAllocationWithExtremums?.find(s => s.type === key);
      let backgroundColor = '';
      const isFeaturedRow = currentBucket?.funds.find(
        f => item.shareClassDetails.isin === f?.isin,
      )?.isFeatured;
      if (assetAllocationWithExtremumsForType) {
        if (
          assetAllocationWithExtremumsForType.isinsOfMaximum.some(
            i => i === item.shareClassDetails.isin,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MAXIMUM_FEATURED_TABLE_CELL
            : COLOR_MAXIMUM_TABLE_CELL;
        }
        if (
          assetAllocationWithExtremumsForType.isinsOfMinimum.some(
            i => i === item.shareClassDetails.isin,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MINIMUM_FEATURED_TABLE_CELL
            : COLOR_MINIMUM_TABLE_CELL;
        }
      }
      return backgroundColor;
    },
    render: (item: Fund): ReactNode => {
      const allocationValue = item?.assetAllocation?.find(
        s => s.type === key,
      )?.value;

      return <span>{roundOrEmpty(allocationValue, 1, '%')}</span>;
    },
    renderType: 'number',
    minWidth: 130,
  };
};

const AssetAllocationColumns = ({
  rowSelectionState,
  currentBucket,
  onSelectAll,
  assetAllocationWithExtremums,
}: WatchlistAssetAllocationColumnsProps): IDataTableColumns[] => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const isMobile = useIsMobileView();
  return [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      backgroundColor: (item: Fund) => {
        const isFeaturedRow = currentBucket?.funds.find(
          f => item.shareClassDetails.isin === f?.isin,
        )?.isFeatured;
        return isFeaturedRow ? COLOR_FUND_FEATURED(isFeaturedRow) : '';
      },
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      render: (fund: Fund) => {
        const { portfolioUpdateDateMs } = fund;
        const formattedPortfolioUpdateDate = formatPortfolioUpdateDate(
          portfolioUpdateDateMs,
        );
        return (
          <div className="flex items-center w-full">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={fund.shareClassDetails.isin}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(fund.shareClassDetails.isin)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(fund.shareClassDetails.isin)
                }
              />
            </div>
            <div className="w-full cursor-pointer">
              <FundInvestmentTrustColumn
                data={{
                  id: fund.shareClassDetails.isin,
                  name: fund.shareClassDetails.code,
                  type: fund.legalStructure,
                  assetClassCode: fund.assetClassCode,
                  fundId: fund.fundId,
                  legalStructure: fund.legalStructure,
                }}
                infoTooltip={`Portfolio as at ${formattedPortfolioUpdateDate}`}
              />
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: isMobile ? 182 : 320,
      sortKey: 'shareClassDetails.code',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    ...[
      AssetAllocationItemTypeEnum.Equities,
      AssetAllocationItemTypeEnum.FixedIncome,
      AssetAllocationItemTypeEnum.Cash,
      AssetAllocationItemTypeEnum.Others,
      AssetAllocationItemTypeEnum.Convertibles,
      AssetAllocationItemTypeEnum.Preferred,
    ].map(s =>
      generateAssetAllocationColumn(
        s,
        assetAllocationWithExtremums,
        currentBucket,
      ),
    ),
  ];
};

export default AssetAllocationColumns;
