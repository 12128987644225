import { Fund } from '@aminsights/contract';
import { BenchmarkType, MsTimePeriod } from '@aminsights/shared';
import { Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import { TableCheckboxHeader } from '@/pages/app/Explore/components/TableCheckbox';
import { roundOrEmpty } from '@/utils/roundOrEmpty';
import useIsMobileView from '@/utils/useIsMobileView';

import FundNameCheckboxWrapper, {
  CheckboxParams,
} from './FundNameCheckboxWrapper';

const getRiskMeasureData = (
  fund: Fund,
  benchmarkType: BenchmarkType,
  year: MsTimePeriod,
) => {
  if (fund) {
    const riskMeasuresDetailData = fund.riskMeasuresDetail?.find(
      r => r.timePeriod === year,
    );
    const relativeRiskMeasuresDetailData =
      fund.relativeRiskMeasuresDetail?.find(
        r => r.timePeriod === year && r.benchmarkType === benchmarkType,
      );
    const maximumDrawdown = riskMeasuresDetailData?.maximumDrawdown;

    return {
      riskMeasuresDetail: riskMeasuresDetailData,
      relativeRiskMeasuresDetail: relativeRiskMeasuresDetailData,
      maximumDrawdown,
    };
  }
  return {};
};

const RiskColumns = (
  checkboxParams: CheckboxParams,
  dataTableRenderedAt: DataTableRenderedAt,
  dateRange: MsTimePeriod,
  showPrimaryShareClassIndicator?: boolean,
): IDataTableColumns[] => {
  const isMobile = useIsMobileView();
  return [
    {
      headerCheckbox: (): React.ReactNode =>
        dataTableRenderedAt !== DataTableRenderedAt.Watchlist && (
          <div className="">
            <TableCheckboxHeader
              onClick={checkboxParams.onCheckedRow}
              topISINs={checkboxParams.topISINs}
              checked={checkboxParams.isAllCheckboxChecked}
            />
          </div>
        ),
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      render: (item: Fund) => (
        <FundNameCheckboxWrapper
          item={item}
          checkboxParams={checkboxParams}
          dataTableRenderedAt={dataTableRenderedAt}
          showPrimaryShareClassIndicator={
            showPrimaryShareClassIndicator ?? false
          }
        />
      ),
      renderType: 'custom',
      align: 'between',
      width: isMobile ? 176 : 240,
      sortKey: 'shareClassDetails.code',
      idSubText: 'fundName',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Beta',
      idSubText: 'beta',
      renderType: 'number',
      isColumnFixed: false,
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.beta.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 80,
      render: (fund: Fund) => {
        const relativeRisk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = relativeRisk?.relativeRiskMeasuresDetail?.beta;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: (
        <span className="whitespace-normal text-right w-full">
          Upside / Downside
        </span>
      ),
      dataTestIdSuffix: 'Upside / Downside',
      idSubText: 'upside-downside',
      renderType: 'number',
      align: 'center',
      sortKey: 'upsideDownsideSortKey',
      width: 120,
      render: (fund: Fund) => {
        const relativeRisk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValueUpside =
          relativeRisk?.relativeRiskMeasuresDetail?.captureRatioTypeOne;
        const riskValueDownside =
          relativeRisk?.relativeRiskMeasuresDetail?.captureRatioTypeTwo;
        const roundUpside = roundOrEmpty(riskValueUpside, 0, '%');
        const roundDownside = roundOrEmpty(riskValueDownside, 0, '%');
        return `${roundUpside} / ${roundDownside}`;
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: <span className="whitespace-normal">Max. Drawdown</span>,
      dataTestIdSuffix: 'Max. Drawdown',
      idSubText: 'max-drawdown',
      renderType: 'number',
      align: 'center',
      sortKey: `scatterPointRiskMeasuresDetails.maximumDrawdown.${dateRange}`,
      width: 100,
      tooltipText: (
        <span className="text-xs md:whitespace-nowrap">
          Based off NAV for investment trusts
        </span>
      ),
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk.maximumDrawdown;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Batting Avg.',
      idSubText: 'batting-average',
      renderType: 'number',
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.battingAverage.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 100,
      render: (fund: Fund) => {
        const relativeRisk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue =
          relativeRisk?.relativeRiskMeasuresDetail?.battingAverage;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: <span className="whitespace-normal">Std. Deviation</span>,
      dataTestIdSuffix: 'Std. Deviation',
      idSubText: 'standard-deviation',
      renderType: 'number',
      align: 'center',
      sortKey: `riskMeasuresDetail.standardDeviation.${dateRange}`,
      width: 100,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.riskMeasuresDetail?.standardDeviation;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Sharpe',
      idSubText: 'sharpe',
      renderType: 'number',
      align: 'center',
      sortKey: `riskMeasuresDetail.sharpeRatio.${dateRange}`,
      width: 70,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.riskMeasuresDetail?.sharpeRatio;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Sortino',
      idSubText: 'sortino',
      renderType: 'number',
      align: 'center',
      sortKey: `riskMeasuresDetail.sortinoRatio.${dateRange}`,
      width: 70,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.riskMeasuresDetail?.sortinoRatio;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Info Ratio',
      idSubText: 'info-ratio',
      renderType: 'number',
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.informationRatio.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 100,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.relativeRiskMeasuresDetail?.informationRatio;
        return roundOrEmpty(riskValue, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: <span className="whitespace-normal">Tracking Error</span>,
      dataTestIdSuffix: 'Tracking Error',
      idSubText: 'tracking-error',
      renderType: 'number',
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.trackingError.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 90,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.relativeRiskMeasuresDetail?.trackingError;
        return roundOrEmpty(riskValue, 0, '%');
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Correlation',
      idSubText: 'correlation',
      renderType: 'number',
      align: 'center',
      sortKey: `relativeRiskMeasuresDetail.correlation.${BenchmarkType.MORNINGSTAR_CATEGORY}.${dateRange}`,
      width: 100,
      render: (fund: Fund) => {
        const risk = getRiskMeasureData(
          fund,
          BenchmarkType.MORNINGSTAR_CATEGORY,
          dateRange,
        );
        const riskValue = risk?.relativeRiskMeasuresDetail?.correlation;
        const rickValueOver100 = riskValue && riskValue / 100;
        return roundOrEmpty(rickValueOver100, 2);
      },
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
  ];
};

export default RiskColumns;
