import { Bucket, Fund } from '@aminsights/contract';
import {
  COLOR_MAXIMUM_FEATURED_TABLE_CELL,
  COLOR_MAXIMUM_TABLE_CELL,
  COLOR_MINIMUM_FEATURED_TABLE_CELL,
  COLOR_MINIMUM_TABLE_CELL,
  LIMIT_FUNDS_FOR_CHARTING,
  MARKET_CAP_TO_SHOW,
  MarketCapLabel,
  MarketCapSortKey,
  MarketCapWithExtremums,
} from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';
import { ReactNode } from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { COLOR_FUND_FEATURED } from '@/constants/colors';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { formatPortfolioUpdateDate } from '@/utils/formatPortfolioUpdateDate';
import { roundOrEmpty } from '@/utils/roundOrEmpty';
import useIsMobileView from '@/utils/useIsMobileView';

type MarketCapColumnsProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  marketCapWithExtremums?: MarketCapWithExtremums[];
  currentBucket?: Bucket | undefined;
  onSelectAll: (isChecked: boolean) => void;
};

const generateMarketCapColumn = (
  label: MarketCapLabel,
  marketCapWithExtremums?: MarketCapWithExtremums[],
  sortKey?: MarketCapSortKey,
  currentBucket?: Bucket | undefined,
): IDataTableColumns => {
  return {
    title: label,
    sortKey: sortKey,
    backgroundColor: (item: Fund) => {
      const marketCapType = label;
      const marketCapWithExtremumsForType = marketCapWithExtremums?.find(
        mc => mc.type === marketCapType,
      );

      let backgroundColor = '';
      const isFeaturedRow =
        currentBucket?.funds.find(f => item.shareClassDetails.isin === f?.isin)
          ?.isFeatured ?? '';
      if (marketCapWithExtremumsForType) {
        if (
          marketCapWithExtremumsForType.isinsOfMaximum.some(
            i => i === item.shareClassDetails.isin,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MAXIMUM_FEATURED_TABLE_CELL
            : COLOR_MAXIMUM_TABLE_CELL;
        }
        if (
          marketCapWithExtremumsForType.isinsOfMinimum.some(
            i => i === item.shareClassDetails.isin,
          )
        ) {
          backgroundColor = isFeaturedRow
            ? COLOR_MINIMUM_FEATURED_TABLE_CELL
            : COLOR_MINIMUM_TABLE_CELL;
        }
      }
      return backgroundColor;
    },
    render: (item: Fund): ReactNode => {
      const marketCapsLong = item.marketCapsLong || [];
      const marketCapValue = marketCapsLong.find(
        data => data.type === label,
      )?.value;

      return <span>{roundOrEmpty(marketCapValue, 1, '%')}</span>;
    },
    renderType: 'number',
    width: 100,
  };
};

const MarketCapColumns = ({
  rowSelectionState,
  marketCapWithExtremums,
  currentBucket,
  onSelectAll,
}: MarketCapColumnsProps): IDataTableColumns[] => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const isMobile = useIsMobileView();
  return [
    {
      title: (
        <div className="whitespace-normal text-start">
          Fund/Investment Trust
        </div>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      backgroundColor: (item: Fund) => {
        const isFeaturedRow = currentBucket?.funds.find(
          f => item.shareClassDetails.isin === f?.isin,
        )?.isFeatured;
        return isFeaturedRow ? COLOR_FUND_FEATURED(isFeaturedRow) : '';
      },
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      render: (item: Fund) => {
        const { fundSize } = item;
        const formattedPortfolioUpdateDate =
          fundSize && fundSize.date && formatPortfolioUpdateDate(fundSize.date);
        return (
          <div className="flex items-center w-full">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={item.shareClassDetails.isin}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(item.shareClassDetails.isin)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(item.shareClassDetails.isin)
                }
              />
            </div>
            <div className="w-full cursor-pointer">
              <FundInvestmentTrustColumn
                data={{
                  id: item.shareClassDetails.isin,
                  name: item.shareClassDetails.code,
                  type: item.legalStructure,
                  assetClassCode: item.assetClassCode,
                  fundId: item.fundId,
                  legalStructure: item.legalStructure,
                }}
                infoTooltip={`Portfolio as at ${formattedPortfolioUpdateDate}`}
              />
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: isMobile ? 182 : 320,
      sortKey: 'shareClassDetails.code',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    ...MARKET_CAP_TO_SHOW.map(data =>
      generateMarketCapColumn(
        data.title,
        marketCapWithExtremums,
        data.key,
        currentBucket,
      ),
    ),
  ];
};

export default MarketCapColumns;
