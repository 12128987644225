import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT, LIMIT_FUNDS_FOR_CHARTING } from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
import { UseOnCheckedRowReturn } from '@/hooks/useOnCheckedRow';
import FundInvestmentTrustColumn from '@/partials/columns/FundInvestmentTrustColumn';
import { roundOrEmpty } from '@/utils/roundOrEmpty';
import useIsMobileView from '@/utils/useIsMobileView';

type IncomeColumnProps = {
  rowSelectionState: UseOnCheckedRowReturn<Fund>;
  onSelectAll: (isChecked: boolean) => void;
};

const IncomeColumns = ({
  rowSelectionState,
  onSelectAll,
}: IncomeColumnProps): IDataTableColumns[] => {
  const { onCheckedRow, checkedRows, isAllCheckboxChecked } = rowSelectionState;
  const isMobile = useIsMobileView();
  return [
    {
      title: (
        <span className="whitespace-normal text-start">
          Fund/Investment Trust
        </span>
      ),
      dataTestIdSuffix: 'Fund/Investment Trust',
      headerCheckbox: (): React.ReactNode => (
        <div className="mr-2" onClick={e => e.stopPropagation()}>
          <Checkbox
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              const isChecked = event.currentTarget.checked;
              onSelectAll(isChecked);
              event.stopPropagation();
            }}
            checked={isAllCheckboxChecked}
          />
        </div>
      ),
      render: (fund: Fund) => {
        return (
          <div className="flex items-center">
            <div className="mr-2 md:mr-7" onClick={e => e.stopPropagation()}>
              <Checkbox
                value={fund.shareClassDetails.isin}
                onChange={e => onCheckedRow([e.target.value])}
                onClick={e => e.stopPropagation()}
                checked={checkedRows.includes(fund.shareClassDetails.isin)}
                disabled={
                  checkedRows.length >= LIMIT_FUNDS_FOR_CHARTING &&
                  !checkedRows.includes(fund.shareClassDetails.isin)
                }
              />
            </div>
            <div>
              <FundInvestmentTrustColumn
                data={{
                  id: fund.shareClassDetails.isin,
                  name: fund.shareClassDetails.code,
                  type: fund.legalStructure,
                  assetClassCode: fund.assetClassCode,
                  fundId: fund.fundId,
                  legalStructure: fund.legalStructure,
                }}
              />
            </div>
          </div>
        );
      },
      renderType: 'custom',
      align: 'between',
      width: isMobile ? 182 : 320,
      sortKey: 'shareClassDetails.code',
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2 }}
          active
        />
      ),
    },
    {
      title: 'Yield',
      render: (item: Fund) => {
        const { yield12Month } = item;
        return roundOrEmpty(yield12Month?.value, 2, '%');
      },
      renderType: 'number',
      width: 80,
      sortKey: 'yield12Month.value',
    },
    {
      title: 'Income Frequency',
      render: (item: Fund) => item.incomeFrequency || EMPTY_DATA_POINT,
      renderType: 'text',
      width: 120,
      sortKey: 'incomeFrequency',
    },
  ];
};

export default IncomeColumns;
