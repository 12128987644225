import { BREAKPOINTS } from '@/constants';
import { useAppContext } from '@/context/AppContext';

const useIsMobileView = () => {
  const { app } = useAppContext();
  const isMobile = app.windowWidth < BREAKPOINTS.MD;
  return isMobile;
};

export default useIsMobileView;
