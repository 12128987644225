import { APP_ROUTE_WATCHLIST } from '@aminsights/shared';
import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as GraphicClose } from '@/assets/svg/icons/icon-close.svg';
import { ReactComponent as IconError } from '@/assets/svg/icons/icon-error.svg';
import Button from '@/components/Button';
import { openJiraForm } from '@/utils/jiraForm';

import style from './style.module.less';

export interface StaticDataForLimitModal {
  entityName: string;
  limitForEntities: number;
  limitForFundsOrTrusts: number;
}

type ModalProps = {
  isFundTrust: boolean;
  staticData: StaticDataForLimitModal;
  isVisible: boolean;
  className?: string;
  onApply?: () => void;
  toggleModal: () => void;
  closeIcon?: React.ReactNode;
};

enum modalContent {
  BUCKET = 'bucket',
  FUND_TRUST = 'funds/trusts',
}

const LimitReachModal: React.FCWithChild<ModalProps> = ({
  isFundTrust,
  staticData,
  isVisible,
  className,
  closeIcon,
  toggleModal,
}) => {
  const generateModalTitle = () => {
    const modalTitle = `You've reached the ${
      isFundTrust
        ? `${staticData.limitForFundsOrTrusts} ${modalContent.FUND_TRUST}`
        : `${
            staticData.limitForEntities
          } ${staticData.entityName.toLocaleLowerCase()}s`
    } limit ${
      isFundTrust ? `per ${staticData.entityName.toLocaleLowerCase()}` : ''
    }`;
    return modalTitle;
  };
  const generateAddMoreText = () => {
    const addMoreText = `If you want to add more ${
      isFundTrust
        ? modalContent.FUND_TRUST
        : `${staticData.entityName.toLowerCase()}s`
    }, please contact us.`;
    return addMoreText;
  };

  return (
    <Modal
      centered={true}
      width={720}
      open={isVisible}
      onCancel={toggleModal}
      className={cx(
        '[&_.ant-modal-body]:pt-10 [&_.ant-modal-footer]:!justify-center [&_.ant-modal-footer]:flex-col',
        '[&_.ant-modal-footer]:items-center',
        className,
      )}
      footer={[
        <Button
          size="large"
          type="primary"
          className="font-semibold !w-max"
          onClick={openJiraForm}
          key="primary"
        >
          Get in Touch
        </Button>,
        <div className="text-center">
          {isFundTrust ? (
            <div>
              or {/* {todo check watchlist or portfolio text?} */}
              <Link
                className="text-[#0072e6]"
                to={`/${APP_ROUTE_WATCHLIST}/manage-buckets`}
              >
                delete other funds/trusts
              </Link>{' '}
              from your watchlist
            </div>
          ) : (
            ''
          )}
        </div>,
      ]}
      closeIcon={closeIcon || <GraphicClose />}
    >
      <div className="text-center">
        <IconError className="text-danger m-auto" width={64} height={64} />
        <div className={style['modal-title']}>{generateModalTitle()}</div>
        <div className={style['modal-info']}>{generateAddMoreText()}</div>
      </div>
    </Modal>
  );
};

export default LimitReachModal;
