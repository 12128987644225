import { Modal } from 'antd';
import React from 'react';

import Button from '@/components/Button';

interface FullPageModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleOnClear?: () => void;
  handleOnApply?: () => void;
  isDisableClear?: boolean;
  isDisableApply?: boolean;
  title?: React.ReactNode;
  children: React.ReactNode;
  showFooter?: boolean;
  dataTestId?: string;
}

const FullPageModal: React.FC<FullPageModalProps> = ({
  isOpen,
  onClose,
  handleOnClear,
  handleOnApply,
  isDisableClear,
  isDisableApply,
  title,
  children,
  showFooter = true,
  dataTestId,
}) => {
  return (
    <Modal
      centered={true}
      open={isOpen}
      onClose={onClose}
      onCancel={() => {
        onClose();
      }}
      title={title}
      className="full-page-modal [&_.ant-modal-header]:drop-shadow-sm [&_.ant-modal-body]:pt-4"
      data-test-id={dataTestId}
      footer={
        showFooter
          ? [
              <Button
                size="large"
                type="link"
                className="font-semibold"
                onClick={() => {
                  handleOnClear?.();
                }}
                key="secondary"
                disabled={isDisableClear}
              >
                Clear
              </Button>,
              <Button
                size="large"
                type="primary"
                className="font-semibold"
                onClick={() => handleOnApply?.()}
                key="primary"
                disabled={isDisableApply}
              >
                Apply
              </Button>,
            ]
          : null
      }
    >
      {children}
    </Modal>
  );
};

export default FullPageModal;
