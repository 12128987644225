import {
  BenchmarkType,
  EMPTY_DATA_POINT,
  isInvestmentTrust,
  MsTimePeriod,
  RiskType,
} from '@aminsights/shared';
import { Tabs } from 'antd';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { Tooltip } from '@/components';
import { Dropdown } from '@/components/Dropdown';
import { BREAKPOINTS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { useFundByIsInParam } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';

import style from './style.module.less';

interface IFilterParams {
  timePeriod: MsTimePeriod;
}

const TabChildren = ({
  setFilter,
  benchmarkType,
  timePeriod,
  benchmarkTitle,
}: {
  setFilter: (props: IFilterParams) => void;
  benchmarkType: BenchmarkType;
  timePeriod?: MsTimePeriod;
  benchmarkTitle?: React.ReactNode | null;
}) => {
  const { data: state } = useFundByIsInParam();
  const filterRiskData = (benchmarkType: BenchmarkType, year: MsTimePeriod) => {
    setFilter({
      timePeriod: year,
    });

    if (state?.fund) {
      const riskMeasuresDetailData =
        state.fund.riskMeasuresDetail?.find(r => r.timePeriod === year) || {};
      const relativeRiskMeasuresDetailData =
        state.fund.relativeRiskMeasuresDetail?.find(
          r => r.timePeriod === year && r.benchmarkType === benchmarkType,
        ) || {};
      const scatterPointRiskMeasuresDetails: {
        maximumDrawdown?: number;
      } = state.fund.riskMeasuresDetail?.find(r => r.timePeriod === year) || {};
      setInfoStatsRiskData({
        ...riskMeasuresDetailData,
        ...relativeRiskMeasuresDetailData,
        maximumDrawdown: scatterPointRiskMeasuresDetails.maximumDrawdown,
      });
    }
  };
  const [infoStatsRiskData, setInfoStatsRiskData] = useState<{
    type?: RiskType;
    timePeriod?: MsTimePeriod;
    benchmarkType?: BenchmarkType;
    standardDeviation?: number;
    maximumDrawdown?: number;
    sharpeRatio?: number;
    sortinoRatio?: number;
    beta?: number;
    alpha?: number;
    correlation?: number;
    trackingError?: number;
    informationRatio?: number;
    captureRatioTypeOne?: number;
    captureRatioTypeTwo?: number;
    battingAverage?: number;
  }>();

  useEffect(() => {
    if (state?.fund) {
      filterRiskData(BenchmarkType.PROSPECTUS_BENCHMARK, MsTimePeriod.ONE_YR);
    }
  }, [state?.fund]);

  useEffect(() => {
    filterRiskData(benchmarkType, timePeriod || MsTimePeriod.ONE_YR);
  }, [benchmarkType, timePeriod]);

  return (
    <div>
      {benchmarkTitle && <>{benchmarkTitle}</>}
      <div className={style['info-stats-risk-content']}>
        <div className={cx(style['info-stats-risk-content-card'], 'flex-1')}>
          <div className={style['info-stats-risk-content-card-wrap']}>
            <div
              className={cx(
                style['info-stats-risk-content-card-wrap-content'],
                style.colBorder,
                style.divider,
              )}
            >
              <div className="w-24">
                <h2>
                  {infoStatsRiskData?.beta
                    ? infoStatsRiskData?.beta.toFixed(2)
                    : EMPTY_DATA_POINT}
                </h2>
                <p>Beta</p>
              </div>
              <div className={cx('w-20', style['section-padding'])}>
                <h2>
                  {infoStatsRiskData?.correlation
                    ? (infoStatsRiskData?.correlation / 100).toFixed(2)
                    : EMPTY_DATA_POINT}
                </h2>
                <p>Correlation</p>
              </div>
            </div>
            <div
              className={cx(
                style['info-stats-risk-content-card-wrap-content'],
                style.divider,
                'w-full',
              )}
            >
              <div className="w-24">
                <h2>
                  {infoStatsRiskData?.alpha
                    ? infoStatsRiskData?.alpha.toFixed(2)
                    : EMPTY_DATA_POINT}
                </h2>
                <p>Alpha</p>
              </div>
              <div className="w-28">
                <h2>
                  {infoStatsRiskData?.battingAverage
                    ? `${infoStatsRiskData?.battingAverage.toFixed(0)}%`
                    : EMPTY_DATA_POINT}
                </h2>
                <p>Batting Average</p>
              </div>
            </div>
          </div>
          <div className={style['info-stats-risk-content-card-wrap']}>
            <div
              className={cx(
                style['info-stats-risk-content-card-wrap-content'],
                style.colBorder,
                style.rowBorder,
                style.divider,
              )}
            >
              <div className="w-24">
                <h2>
                  {' '}
                  {infoStatsRiskData?.captureRatioTypeOne
                    ? `${infoStatsRiskData?.captureRatioTypeOne.toFixed(0)}%`
                    : EMPTY_DATA_POINT}
                </h2>
                <p>Upside</p>
              </div>
              <div className={cx('w-20', style['section-padding'])}>
                <h2>
                  {' '}
                  {infoStatsRiskData?.captureRatioTypeTwo
                    ? `${infoStatsRiskData?.captureRatioTypeTwo.toFixed(0)}%`
                    : EMPTY_DATA_POINT}
                </h2>
                <p>Downside</p>
              </div>
            </div>
            <div
              className={cx(
                style['info-stats-risk-content-card-wrap-content'],
                style.rowBorder,
                'w-full',
              )}
            >
              <div className="w-24">
                <h2>
                  {infoStatsRiskData?.trackingError
                    ? `${infoStatsRiskData?.trackingError.toFixed(0)}%`
                    : EMPTY_DATA_POINT}
                </h2>
                <p>Tracking error</p>
              </div>
              <div className="w-28">
                <h2>
                  {infoStatsRiskData?.informationRatio
                    ? infoStatsRiskData?.informationRatio.toFixed(2)
                    : EMPTY_DATA_POINT}
                </h2>
                <p>Information Ratio</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx(
            style['info-stats-risk-content-card'],
            style['info-stats-risk-content-card-two'],
          )}
        >
          <div
            className={cx(style['info-stats-risk-content-card-wrap'], 'w-full')}
          >
            <div
              className={cx(
                style['info-stats-risk-content-card-wrap-content'],
                style.divider,
              )}
            >
              <div className="w-24">
                <h2>
                  {infoStatsRiskData?.standardDeviation
                    ? `${infoStatsRiskData?.standardDeviation.toFixed(0)}%`
                    : EMPTY_DATA_POINT}
                </h2>

                <p>Std. Deviation</p>
              </div>
              <div className={cx('w-28', style['section-padding'])}>
                <h2>
                  {infoStatsRiskData?.maximumDrawdown
                    ? `${infoStatsRiskData?.maximumDrawdown.toFixed(0)}%`
                    : EMPTY_DATA_POINT}
                </h2>
                <div className="flex relative">
                  <p>Max. Drawdown</p>
                  {state?.fund.legalStructure &&
                    isInvestmentTrust(state?.fund.legalStructure) && (
                      <Tooltip
                        title="Based off NAV for investment trusts"
                        placement="bottomRight"
                        color="#3E414B"
                        className="absolute top-[-2px] right-0"
                      >
                        <InfoIcon className="icon text-base" />
                      </Tooltip>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={cx(style['info-stats-risk-content-card-wrap'], 'w-full')}
          >
            <div
              className={cx(
                style['info-stats-risk-content-card-wrap-content'],
                style.rowBorder,
              )}
            >
              <div className="w-24">
                <h2>
                  {infoStatsRiskData?.sharpeRatio
                    ? infoStatsRiskData?.sharpeRatio.toFixed(2)
                    : EMPTY_DATA_POINT}
                </h2>
                <p>Sharpe Ratio</p>
              </div>
              <div className="w-28">
                <h2>
                  {infoStatsRiskData?.sortinoRatio
                    ? infoStatsRiskData?.sortinoRatio.toFixed(2)
                    : EMPTY_DATA_POINT}
                </h2>

                <p>Sortino Ratio</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Risk: React.FCWithChild = () => {
  const { app } = useAppContext();
  const { data: state } = useFundByIsInParam();
  const [selectedFilterParams, setSelectedFilterParams] =
    useState<IFilterParams>();

  const yearDropdown = [
    {
      value: MsTimePeriod.ONE_YR,
      label: '1 year',
    },
    {
      value: MsTimePeriod.THREE_YRS,
      label: '3 years',
    },
    {
      value: MsTimePeriod.FIVE_YRS,
      label: '5 years',
    },
  ];

  const prospectusBenchmark = state?.fund?.primaryBenchmarkFormatted;

  return (
    <div className={style['info-stats-risk']}>
      <div className={style['info-stats-risk-wrapper']}>
        <Tabs
          items={[
            {
              label: (
                <div data-test-id="fundDetailsPerfRiskTabRiskProspectusBenchmarkTab">{`Prospectus ${
                  (app?.windowWidth || 0) <= BREAKPOINTS.XS
                    ? 'bmk'
                    : 'benchmark'
                }`}</div>
              ),
              key: BenchmarkType.PROSPECTUS_BENCHMARK,
              children: (
                <div data-test-id="fundDetailsPerfRiskTabRiskProspectusBenchmarkTable">
                  <TabChildren
                    benchmarkType={BenchmarkType.PROSPECTUS_BENCHMARK}
                    setFilter={setSelectedFilterParams}
                    timePeriod={selectedFilterParams?.timePeriod}
                    benchmarkTitle={
                      prospectusBenchmark ? (
                        <p className="font-normal text-neutral pb-3 leading-5">
                          ({prospectusBenchmark})
                        </p>
                      ) : null
                    }
                  />
                </div>
              ),
            },
            {
              label: (
                <div data-test-id="fundDetailsPerfRiskTabRiskCategoryIndexTab">
                  Category index
                </div>
              ),
              key: BenchmarkType.MORNINGSTAR_CATEGORY,
              children: (
                <div data-test-id="fundDetailsPerfRiskTabRiskCategoryIndexTable">
                  <TabChildren
                    benchmarkType={BenchmarkType.MORNINGSTAR_CATEGORY}
                    setFilter={setSelectedFilterParams}
                    timePeriod={selectedFilterParams?.timePeriod}
                  />
                </div>
              ),
            },
          ]}
          className={style['info-stats-risk-tab-container']}
          tabBarExtraContent={
            <div data-test-id="fundDetailsPerfRiskTabRiskTimePeriodDropdown">
              <Dropdown
                rounded
                label="1 year"
                onSelect={(selectedValue: MsTimePeriod) => {
                  setSelectedFilterParams(p => ({
                    ...p,
                    timePeriod: selectedValue,
                  }));
                }}
                items={yearDropdown}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Risk;
