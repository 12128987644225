import { Skeleton, Tooltip } from 'antd';
import cx from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import style from '../style.module.less';

export const MatrixFundName: FC<{
  index: number;
  fundName: string;
  isin: string;
  isLoading: boolean;
  isFeatured?: boolean;
  className?: string;
}> = ({ index, fundName, isin, isLoading, className, isFeatured }) => {
  return (
    <div
      className={cx(
        'flex items-center h-14',
        style['matrix-item-height'],
        className,
      )}
    >
      <div
        className="border border-gray-300 p-3 rounded-lg w-full whitespace-nowrap overflow-hidden overflow-ellipsis text-xs"
        style={{
          backgroundColor: isFeatured ? '#E6F9F6' : 'transparent',
        }}
      >
        {isLoading && (
          <div className="h-5 w-full">
            <Skeleton.Node
              className={cx('rounded', style['override-skeleton'])}
              active
              children={<></>}
            />
          </div>
        )}
        {!isLoading && (
          <>
            <strong className="pr-2 font-semibold inline-block">
              {index + 1}
            </strong>
            &nbsp;
            <Tooltip
              overlayClassName="pointer-events-none"
              title={!isLoading ? fundName : ''}
              placement="bottomLeft"
              showArrow={false}
              mouseLeaveDelay={0}
              align={{
                offset: [0, -10],
              }}
            >
              <Link to={`/fund/${isin}/details/summary`}>
                <span className="inline">{fundName}</span>
              </Link>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export const MatrixNumber: FC<{
  index: number;
  isLoading: boolean;
  fundName: string;
  className?: string;
  isFeatured?: boolean;
  spanClassName?: string;
}> = ({ index, className, fundName, isFeatured, isLoading, spanClassName }) => {
  return (
    <div
      className={cx(
        'flex items-center font-semibold text-center justify-center w-full h-14 text-xs',
        style['matrix-item-height'],
        className,
      )}
    >
      {isLoading && (
        <div className="w-full h-4 px-3">
          <Skeleton.Node
            className={cx('h-4 rounded', style['override-skeleton'])}
            active
            children={<></>}
          />
        </div>
      )}
      {!isLoading && (
        <Tooltip
          overlayClassName="pointer-events-none"
          title={!isLoading ? fundName : ''}
          placement="bottom"
          showArrow={false}
          mouseLeaveDelay={0}
          align={{
            offset: [0, -10],
          }}
        >
          <span
            className={cx(
              'mx-2 xl:mx-3 xxl:mx-4 px-2 rounded cursor-default leading-5',
              spanClassName,
            )}
            style={{
              backgroundColor: isFeatured ? '#E6F9F6' : 'transparent',
            }}
          >
            {index + 1}
          </span>
        </Tooltip>
      )}
    </div>
  );
};
