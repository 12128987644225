import {
  Bucket,
  BucketRequestBody,
  ResourceUserRoleEnum,
} from '@aminsights/contract';
import { APP_ROUTE_WATCHLIST, AUTH0_NAMESPACE } from '@aminsights/shared';
import { Dropdown, Input, Menu } from 'antd';
import cx from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SharedIcon } from '@/assets/svg/icons/icon-shared.svg';
import { ReactComponent as MeatballMenu } from '@/assets/svg/meatball-menu.svg';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { AxiosAuthContext } from '@/context/AxiosAuthContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import {
  useDeleteBucket,
  useRemoveSharedBucket,
  useUpdateBucket,
} from '@/hooks/query-hooks/bucket-hooks/useManageBuckets';
import { WATCHLIST } from '@/hooks/query-hooks/watchlist-hooks/query-keys';
import { useCurrentWatchlist } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import BenchmarksDropdown from '@/partials/BenchmarksDropdown';
import ShareModalBucket from '@/partials/ManageBuckets/ShareBucketModal';
import ConfirmationModalDanger from '@/partials/Modal/ConfirmationModalDanger';
import queryClient from '@/queryClient';

import style from '../style.module.less';
import RenderFunds from './renderFunds';
import BucketSkeleton from './Skeleton';

const BucketDefault: React.FCWithChild<{
  id: string;
  name: string;
  index: string;
  funds: Bucket['funds'];
  order: number;
  active?: boolean;
}> = props => {
  const history = useHistory();
  const { id, name, index, funds, order, active } = props;
  const { dispatch: dispatchApp } = useAppContext();
  const renameBucketRef = useRef<HTMLDivElement>(null);
  const [isBucketNameInputShown, setIsBucketNameInputShown] = useState(false);
  const [isIndexDropdownShown, setIsIndexDropdownShown] = useState(false);
  const [removeSharedBucketOpen, setRemoveSharedBucketOpen] = useState(false);
  const [isRemoveBucketModalOpen, setIsRemoveBucketModalOpen] = useState(false);
  const [renameBucketName, setRenameBucketName] = useState(name);
  const [isBucketNameExists, setIsBucketNameExists] = useState(false);
  const [benchmarkIndex, setBenchmarkIndex] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { state: authState } = useContext(AxiosAuthContext);
  const currentUser = authState.decodedToken;
  const {
    data: benchmarkOptionsResponse,
    isLoading: isBenchmarkOptionsLoading,
  } = useBenchmarkOptions();

  const currentWatchlist = useCurrentWatchlist();
  const { isLoading } = currentWatchlist;
  const updateBucketMutation = useUpdateBucket();
  const deleteMutation = useDeleteBucket();
  const removeBucketMutation = useRemoveSharedBucket({
    onSuccess: async () => setRemoveSharedBucketOpen(false),
  });

  const bucketUsers =
    currentWatchlist.data?.buckets?.find(b => b.id === id)?.users || [];
  const currentBucketUser = bucketUsers.find(bu => bu.id === currentUser.sub);
  const isBucketShared = (bucketUsers?.length || 0) > 1;
  const canManageBucket =
    ResourceUserRoleEnum.Editor === currentBucketUser?.role;

  const handleRemoveNewBucketModalOpen = () => {
    setIsRemoveBucketModalOpen(prev => !prev);
  };

  const bucketMenuItems = useMemo(() => {
    const allItems = [
      {
        label: 'Rename Bucket',
        key: 'renameBucket',
        onClick: () => setIsBucketNameInputShown(true),
        'data-test-id': 'bucketRenameButton',
      },
      {
        label: 'Change Bucket Order',
        key: 'changeBucketOrder',
        onClick: () =>
          history.push(`/${APP_ROUTE_WATCHLIST}/change-bucket-order`),
        'data-test-id': 'bucketChangeBucketOrderButton',
      },
      ...(Boolean(authState.decodedToken?.[`${AUTH0_NAMESPACE}/tenant_id`])
        ? [
            {
              label: 'Share',
              key: 'shareBucket-menu',
              children: [
                {
                  label: 'Share Bucket',
                  key: 'shareBucket-opt',
                  onClick: () => setIsShareModalOpen(true),
                },
              ],
            },
          ]
        : []),
      {
        label: <span className="danger">Delete Bucket</span>,
        key: 'deleteBucket',
        'data-test-id': 'bucketDeleteBucketButton',
        onClick: () => setIsRemoveBucketModalOpen(true),
      },
    ];
    if (currentBucketUser?.role !== ResourceUserRoleEnum.Editor) {
      const nonOwnerActions = [
        {
          label: <span className="danger">Remove Bucket</span>,
          key: 'removeBucket',
          onClick: () => setRemoveSharedBucketOpen(true),
          'data-test-id': 'bucketRemoveBucketButton',
        },
      ];
      if (!canManageBucket) {
        return [
          ...allItems.filter(i => i.key === 'changeBucketOrder'),
          ...nonOwnerActions,
        ];
      }
      return [...allItems, ...nonOwnerActions];
    }
    return allItems;
  }, [
    setIsBucketNameInputShown,
    setIsIndexDropdownShown,
    canManageBucket,
    setIsShareModalOpen,
    setIsRemoveBucketModalOpen,
    bucketUsers,
  ]);

  const bucketMenu = (
    <Menu className="custom-dropdown-menu" items={bucketMenuItems} />
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setRenameBucketName(e.target.value);
  };

  const updateBucketName = (bucketName = '') => {
    updateBucketMutation.mutate(
      {
        bucketId: id,
        bucket: {
          name: bucketName,
        },
      },
      {
        onSuccess: () => {
          setRenameBucketName(bucketName);
          dispatchApp({
            type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
            payload: { text: 'Successfully renamed bucket' },
          });
        },
        onError: () => {
          setRenameBucketName('');
          dispatchApp({
            type: APP_ACTIONS.SET_ERROR_MESSAGE,
            payload: 'Bucket not renamed',
          });
        },
      },
    );
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isBucketNameExists) {
      if (e.key === 'Enter') {
        if (renameBucketName !== name) updateBucketName(renameBucketName);
        setIsBucketNameInputShown(false);
      }
    }
  };

  useEffect(() => {
    const isBucketNameExists = currentWatchlist.data?.buckets
      ? currentWatchlist.data.buckets.some(
          b => b.name === renameBucketName && b.name !== name,
        )
      : false;

    setIsBucketNameExists(isBucketNameExists);
  }, [renameBucketName, isBucketNameInputShown]);

  useEffect(() => {
    if (!isBucketNameExists) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [renameBucketRef, isBucketNameExists, renameBucketName]);

  const handleClickOutside = () => {
    if (
      renameBucketRef.current &&
      renameBucketRef.current.id &&
      renameBucketName !== name
    ) {
      updateBucketName(renameBucketRef.current?.id);
    }
    setIsBucketNameInputShown(false);
  };

  const onDeleteClick = () => {
    return deleteMutation.mutateAsync(id);
  };

  if (isLoading) {
    return <BucketSkeleton loading={true} />;
  }

  return (
    <>
      <span className={style['bucket-count']}>Bucket {order + 1}</span>
      <div className={cx(style.bucket, active && style['bucket-active'])}>
        <div>
          <div className={style.bucket__head}>
            <div
              className={style['bucket__head-title']}
              role="button"
              onClick={() => {
                if (canManageBucket) setIsBucketNameInputShown(true);
              }}
            >
              <div className={cx({ hidden: isBucketNameInputShown })}>
                {renameBucketName || name} <span>({funds?.length || 0}) </span>
              </div>
              {isBucketNameInputShown && (
                <div ref={renameBucketRef} id={renameBucketName}>
                  <Input
                    autoFocus
                    type="text"
                    defaultValue={name}
                    value={renameBucketName || name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e)
                    }
                    onMouseDown={e => e.stopPropagation()}
                    onKeyDown={onKeyDown}
                    data-test-id="bucketRenameInput"
                  />
                  {isBucketNameExists && (
                    <div className="font-medium text-xs text-danger pt-1 pl-1">
                      Bucket name already exists
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className={style['bucket__head-actions']}>
              {isBucketShared && (
                <SharedIcon
                  height={30}
                  width={30}
                  style={{ padding: 'unset' }}
                  onClick={
                    canManageBucket
                      ? () => setIsShareModalOpen(true)
                      : undefined
                  }
                  className={cx(canManageBucket && 'cursor-pointer')}
                />
              )}
              <Dropdown
                overlay={bucketMenu}
                placement="bottomRight"
                trigger={['click']}
              >
                <div data-test-id="bucketMenuDropdown">
                  <MeatballMenu />
                </div>
              </Dropdown>
            </div>
          </div>

          {!isIndexDropdownShown && index.length ? (
            <p
              role="button"
              onClick={() => {
                if (canManageBucket) setIsIndexDropdownShown(true);
              }}
              className={style.bucket__index}
            >
              {benchmarkIndex || index}
            </p>
          ) : (
            <div className="pb-3 pt-2">
              <BenchmarksDropdown
                className={style['index-dropdown']}
                placeholder={index || 'Select index'}
                value={index}
                benchmarkOptions={benchmarkOptionsResponse || []}
                onSelect={selectedValue => {
                  const indexName = benchmarkOptionsResponse?.find(
                    b => b.id === selectedValue,
                  )?.name;
                  updateBucketMutation.mutateAsync(
                    {
                      bucketId: id,
                      /**
                       * TODO: There is a type issue,
                       * BucketUpdateRequestBody does not have index but is required
                       * to work. buckets.controller.ts the updateBucket function actually
                       * expects BucketRequestBody NOT BucketUpdateRequestBody.
                       */
                      bucket: {
                        indexSecId: selectedValue,
                        index: indexName,
                      } as BucketRequestBody,
                    },
                    {
                      onSuccess: () => {
                        setBenchmarkIndex(indexName ?? '');
                        setRenameBucketName('');
                        dispatchApp({
                          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                          payload: { text: 'Successfully updated index' },
                        });
                      },
                      onError: () => {
                        setRenameBucketName('');
                        dispatchApp({
                          type: APP_ACTIONS.SET_ERROR_MESSAGE,
                          payload: 'Index not updated',
                        });
                      },
                      onSettled: () => {
                        queryClient.invalidateQueries([WATCHLIST]);
                        setIsIndexDropdownShown(false);
                      },
                    },
                  );
                }}
                onClear={() => {
                  updateBucketMutation.mutateAsync(
                    {
                      bucketId: id,
                      bucket: {
                        index: '',
                        indexSecId: '',
                      } as BucketRequestBody,
                    },
                    {
                      onSuccess: () => {
                        setBenchmarkIndex('');
                        dispatchApp({
                          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
                          payload: { text: 'Successfully updated index' },
                        });
                      },
                      onError: () => {
                        dispatchApp({
                          type: APP_ACTIONS.SET_ERROR_MESSAGE,
                          payload: 'Index not updated',
                        });
                      },
                      onSettled: () => {
                        queryClient.invalidateQueries([WATCHLIST]);
                        setIsIndexDropdownShown(false);
                      },
                    },
                  );
                }}
                isOptionsLoading={isBenchmarkOptionsLoading}
                hideCustomOptions
              />
            </div>
          )}

          <div className={style.bucket__body}>
            <RenderFunds
              funds={funds || []}
              bucketId={id || ''}
              showActions={canManageBucket}
            />
          </div>
        </div>
      </div>
      <ConfirmationModalDanger
        modalInfo={{
          title: isBucketShared
            ? `Delete '${name}' for all users?`
            : `Delete '${name}'?`,
          description:
            'The funds inside this bucket will also be deleted and removed from watchlist.',
          primaryActionLabel: 'Delete',
          succesMessage: `Successfully deleted '${name}'`,
          errorMessage: `'${name}' not deleted`,
        }}
        isVisible={isRemoveBucketModalOpen}
        toggleModal={handleRemoveNewBucketModalOpen}
        onConfirm={onDeleteClick}
      />
      <ConfirmationModalDanger
        modalInfo={{
          title: `Remove '${name}'?`,
          description:
            'This shared bucket will be removed from your watchlist.',
          primaryActionLabel: 'Remove',
          succesMessage: `Successfully removed '${name}' from your watchlist.`,
          errorMessage: `'${name}' not removed.`,
        }}
        loading={removeBucketMutation.isLoading}
        isVisible={removeSharedBucketOpen}
        toggleModal={() => setRemoveSharedBucketOpen(false)}
        onConfirm={async () => removeBucketMutation.mutate(id)}
      />
      {
        <ShareModalBucket
          visible={isShareModalOpen}
          title={name}
          bucketId={id}
          onClose={() => setIsShareModalOpen(false)}
        />
      }
    </>
  );
};

export default BucketDefault;
