import { DataSourceType, FundBroadAssetClass } from '@aminsights/contract';
import {
  buildFundDetailsPath,
  DATE_PERIOD_FILTER,
  EDateFilterValues,
  isEquityBroadAssetClass,
  isFixedIncomeBroadAssetClass,
  isInvestmentTrust,
  MARKET_CAP_TO_SHOW,
  MsTimePeriod,
  SalesPosition,
} from '@aminsights/shared';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { Collapse, Tooltip } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as InfoIcon } from '@/assets/svg/icons/icon-info.svg';
import { ReactComponent as EditIcon } from '@/assets/svg/icons/icon-pencil-v2.svg';
import { Button, Dropdown } from '@/components';
import SectorEquityLegend from '@/components/SectorEquityLegend';
import { BREAKPOINTS, FundTitleLabel } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { useBenchmarkOptions } from '@/hooks/query-hooks/benchmark-hooks/useManageBenchmarks';
import { useGetFundsToCompare } from '@/hooks/query-hooks/fund-hooks/useFundByIsin';
import useBenchmarkHoldingsByFundShareClassId from '@/hooks/query-hooks/useBenchmarkHoldingsByFundShareClassId';
import useBenchmarkBySecId from '@/hooks/query-hooks/watchlist-hooks/useBenchmarkBySecId';
import BasePageWithMetadata from '@/pages/app/BasePageWithMetadata';
import AbsoluteChartTitle from '@/partials/Charts/absolute/AbsoluteChartTitle';
import AbsoluteChartV2 from '@/partials/Charts/absolute/AbsoluteChartV2';
import { RipsReturnTypeProvider } from '@/partials/Charts/Context';
import RelativeChartTitle from '@/partials/Charts/relative/RelativeChartTitle';
import RelativeChartV2 from '@/partials/Charts/relative/RelativeChartV2';
import { parseDateValue, useDatePickerContext } from '@/partials/DatePicker';
import SectionBackButton from '@/partials/Sections/SectionBackButton';

import Loader from '../Loader';
import CompareAnnualisedPerformance from './CompareAnnualisedPerformance';
import CompareCalendarYearPerformance from './CompareCalendarYearPerformance';
import { CompareCollapse } from './CompareCollapse';
import CompareFundSelectModal from './CompareFundSelectModal';
import CompareRisk from './CompareRisk';
import CompareCreditQuality from './CompareTables/CompareCreditQuality';
import { CompareFundBasics } from './CompareTables/CompareFundBasics';
import CompareGeography from './CompareTables/CompareGeography';
import CompareHoldings from './CompareTables/CompareHoldings';
import CompareMarketCap from './CompareTables/CompareMarketCap';
import CompareMaturity from './CompareTables/CompareMaturity';
import CompareSectors from './CompareTables/CompareSectors';
import { extractFundTitle } from './CompareTables/extractFundTitle';
import styles from './style.module.less';
import {
  calculateDataForGeographyCompareTable,
  calculateDataForHoldingsCompareTable,
  calculateDataForSectorsCompareTable,
} from './utils/CompareDataCalculations';

const { Panel } = Collapse;

const CompareFundPage = () => {
  const { app } = useAppContext();
  const { search } = useLocation();
  const { data: benchmarkOptionsResponse } = useBenchmarkOptions();
  const query = new URLSearchParams(search);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const windowWidth = app?.windowWidth || 0;

  const FUND_BASICS_KEY = 'fundBasics';

  const panelKeys = [
    'geography',
    'sectors',
    'fundBasics',
    'holdings',
    'market-cap',
    'maturity',
    'credit-quality',
    'absolute-chart',
    'relative-chart',
    'annualised-performance',
    'calendar-performance',
    'risk',
  ];

  const [activeKeys, setActiveKeys] = useState<string[]>(
    windowWidth > BREAKPOINTS.SM ? panelKeys : [FUND_BASICS_KEY],
  );

  const handleCollapseChange = (key: string | string[]) => {
    setActiveKeys(Array.isArray(key) ? key : [key]);
  };

  const funds = useMemo(() => {
    return Array.from(
      new Array(4),
      (_, idx) => query.get(`fund${idx + 1}`) || '',
    )?.filter(Boolean);
  }, [query]);

  const benchmarkSecId = query.get('benchmarkSecId') ?? undefined; // I have no idea what to do with index for now
  const matchingBenchmark = benchmarkOptionsResponse?.find(
    b => b.secId === benchmarkSecId,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (funds && funds.length < 2) {
    // TODO Show a proper error screen - you should not be able to land here without two actual funds
    return <div>Invalid query params</div>;
  }

  // Fetch data with react-query
  const validFundQueries = useGetFundsToCompare(funds);
  const validFundQueriesData = validFundQueries?.data?.filter(q => !!q) || [];
  const benchmarkQuery = useBenchmarkBySecId(matchingBenchmark?.secId, {
    refetchOnWindowFocus: false,
  });

  const fundDates = useMemo(() => {
    return validFundQueriesData.map(fund =>
      dayjs(fund?.portfolioSectorUpdateDate).format(DISPLAY_DATE_FORMAT),
    );
  }, [validFundQueriesData]);

  const hasInvestmentTrust = validFundQueriesData.some(
    fund => fund?.legalStructure && isInvestmentTrust(fund.legalStructure),
  );

  const holdingsCompareData = useMemo(() => {
    if (validFundQueriesData?.length) {
      return calculateDataForHoldingsCompareTable(validFundQueriesData);
    }
  }, [validFundQueriesData]);

  const isNotEquityOrFI = useMemo(() => {
    if (validFundQueriesData?.length) {
      const isEquity = isEquityBroadAssetClass(
        validFundQueriesData[0].broadAssetClass,
      );
      const isFixedIncome = isFixedIncomeBroadAssetClass(
        validFundQueriesData[0].broadAssetClass,
      );
      return !isEquity && !isFixedIncome;
    }
  }, [validFundQueriesData]);

  const isDataSourcePadi = useMemo(() => {
    if (validFundQueriesData?.length) {
      return (
        validFundQueriesData[0].portfolioDataSource === DataSourceType.Padi
      );
    } else {
      return false;
    }
  }, [validFundQueriesData]);

  const broadAssetClassType = useMemo(() => {
    if (validFundQueriesData?.length) {
      return validFundQueriesData[0].broadAssetClass as FundBroadAssetClass;
    }
  }, [validFundQueriesData]);

  const sectorsEquityFundLeftCount = useMemo(() => {
    return validFundQueriesData[0]?.sectorsEquity?.length ?? 0;
  }, [validFundQueriesData]);

  const hasSectorsEquity = sectorsEquityFundLeftCount !== 0;

  const countriesEquityFundLeftCount = useMemo(() => {
    return validFundQueriesData[0]?.countriesEquity?.length ?? 0;
  }, [validFundQueriesData]);

  const hasGeographyCountriesEquity = countriesEquityFundLeftCount !== 0;

  const sectorsFixedIncomeFundLeftCount = useMemo(() => {
    return validFundQueriesData[0]?.sectorsFixedIncome?.length ?? 0;
  }, [validFundQueriesData]);

  const hasSectorsFixedIncome = sectorsFixedIncomeFundLeftCount !== 0;

  const countriesFixedIncomeFundLeftCount = useMemo(() => {
    return validFundQueriesData[0]?.countriesFixedIncome?.length ?? 0;
  }, [validFundQueriesData]);

  const hasGeographyFixedIncome = countriesFixedIncomeFundLeftCount !== 0;

  const hasMarketCapsLongData = useMemo(() => {
    if (validFundQueriesData?.length) {
      return (
        (validFundQueriesData[0].marketCapsLong?.length ?? 0) !== 0 || false
      );
    }
  }, [validFundQueriesData]);

  const hasMaturity = useMemo(() => {
    if (validFundQueriesData?.length) {
      return (
        (validFundQueriesData[0].bondMaturityRange?.filter(
          v => v.salePosition === SalesPosition.Long,
        )?.length ?? 0) > 0
      );
    }
  }, [validFundQueriesData]);

  const hasCreditQuality = useMemo(() => {
    if (validFundQueriesData?.length) {
      return (
        (validFundQueriesData[0]?.bondCreditQualityBreakdown?.length ?? 0) > 0
      );
    }
  }, [validFundQueriesData]);

  const isEquitySectorAndDataSourceValid = useMemo(() => {
    return (
      broadAssetClassType === FundBroadAssetClass.Equity ||
      ((isNotEquityOrFI || isDataSourcePadi) && hasSectorsEquity)
    );
  }, [isNotEquityOrFI, isDataSourcePadi, hasSectorsEquity]);

  const isEquityGeographyAndDataSourceValid = useMemo(() => {
    return (
      broadAssetClassType === FundBroadAssetClass.Equity ||
      ((isDataSourcePadi || isNotEquityOrFI) && hasGeographyCountriesEquity)
    );
  }, [isNotEquityOrFI, isDataSourcePadi, hasGeographyCountriesEquity]);

  const isFixedIncomeSectorAndDataSourceValid = useMemo(() => {
    return (
      !isDataSourcePadi &&
      (broadAssetClassType === FundBroadAssetClass.FixedIncome ||
        (isNotEquityOrFI && hasSectorsFixedIncome))
    );
  }, [isNotEquityOrFI, isDataSourcePadi, hasSectorsFixedIncome]);

  const isFixedIncomeGeographyAndDataSourceValid = useMemo(() => {
    return (
      !isDataSourcePadi &&
      (broadAssetClassType === FundBroadAssetClass.FixedIncome ||
        (isNotEquityOrFI && hasGeographyFixedIncome))
    );
  }, [isNotEquityOrFI, isDataSourcePadi, hasGeographyFixedIncome]);

  const isFixedIncome = useMemo(() => {
    return broadAssetClassType === FundBroadAssetClass.FixedIncome;
  }, [broadAssetClassType]);

  const isMaturityDataSourceValid = useMemo(() => {
    return isFixedIncome || (isNotEquityOrFI && hasMaturity);
  }, [isNotEquityOrFI, isFixedIncome, hasMaturity]);

  const isCreditQualityDataSourceValid = useMemo(() => {
    return isFixedIncome || (isNotEquityOrFI && hasCreditQuality);
  }, [isNotEquityOrFI, isFixedIncome, hasCreditQuality]);

  const geographyCompareEquityData = useMemo(() => {
    if (validFundQueriesData?.length) {
      return isEquityGeographyAndDataSourceValid
        ? calculateDataForGeographyCompareTable(true, validFundQueriesData)
        : { data: [], geographies: [[]], flatGeographies: [] };
    }
  }, [validFundQueriesData, isEquityGeographyAndDataSourceValid]);

  const geographyCompareFixedIncomeData = useMemo(() => {
    if (validFundQueriesData?.length) {
      return isFixedIncomeGeographyAndDataSourceValid
        ? calculateDataForGeographyCompareTable(false, validFundQueriesData)
        : { data: [], geographies: [[]], flatGeographies: [] };
    }
  }, [validFundQueriesData, isFixedIncomeGeographyAndDataSourceValid]);

  const sectorsEquityCompareData = useMemo(() => {
    if (validFundQueriesData.length) {
      return isEquitySectorAndDataSourceValid
        ? calculateDataForSectorsCompareTable(true, validFundQueriesData)
        : { data: [], sectors: [[]], flatSectors: [] };
    }
  }, [validFundQueriesData, isEquitySectorAndDataSourceValid]);

  const sectorsFixedIncomeCompareData = useMemo(() => {
    if (validFundQueriesData.length) {
      return isFixedIncomeSectorAndDataSourceValid
        ? calculateDataForSectorsCompareTable(false, validFundQueriesData)
        : { data: [], sectors: [[]], flatSectors: [] };
    }
  }, [validFundQueriesData, isFixedIncomeSectorAndDataSourceValid]);

  const benchmarkHoldingsQuery = useBenchmarkHoldingsByFundShareClassId(
    matchingBenchmark?.secId,
    (sectorsEquityCompareData?.data.map(s => s.type) ?? []).concat(
      sectorsFixedIncomeCompareData?.data.map(s => s.type) ?? [],
    ),
    holdingsCompareData?.data.filter(d => d.isin).map(h => h.isin) ?? [],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (
      geographyCompareEquityData?.data
        .filter(g => !!g.id)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(g => g.id!) ?? []
    ).concat(
      geographyCompareFixedIncomeData?.data
        .filter(g => !!g.id)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(g => g.id!) ?? [],
    ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const marketCapCompareData = useMemo(() => {
    if (validFundQueriesData.length) {
      return {
        types: Object.values(MARKET_CAP_TO_SHOW).map(m => m.title),
        marketCap: validFundQueriesData.map(f => f.marketCapsLong),
      };
    } else {
      return {
        types: [],
        marketCap: [[]],
      };
    }
  }, [validFundQueriesData]);

  const isSomethingLoading =
    validFundQueries.isLoading || benchmarkQuery.isLoading;

  // We'll use the equity template based on the first fund selected. False would be bond template. Other templates to come in the future.
  const useEquityTemplate = isEquityBroadAssetClass(
    validFundQueriesData?.[0]?.broadAssetClass ?? '',
  );
  const showIndexColumn = useEquityTemplate && !!matchingBenchmark?.secId;

  if (validFundQueries.isError) {
    // eslint-disable-next-line no-console
    console.group(`One or both of the queries threw an error`);
    console.error(validFundQueries.error);
    // eslint-disable-next-line no-console
    console.groupEnd();
    return null;
  }

  const isinsForChartRequest = useMemo(() => funds, [funds]);

  const orderedFunds = !!validFundQueriesData.length
    ? validFundQueriesData.map(f => {
        return {
          ...f,
          isin: f.shareClassDetails.isin,
          shareClassDetailsCode: f.shareClassDetails.code,
        };
      })
    : [];

  const renderComparePanelGeography = (
    broadAssetClass: FundBroadAssetClass,
    title: FundTitleLabel,
  ) => (
    <Panel
      showArrow={true}
      className="bg-white overflow-hidden"
      key="geography"
      header={
        <h3 className="py-4 pl-4">
          {isDataSourcePadi ? FundTitleLabel.GEOGRAPHY : title}
        </h3>
      }
    >
      <CompareGeography
        funds={validFundQueriesData}
        showIndexColumn={showIndexColumn}
        loading={isSomethingLoading}
        benchmarkCountries={benchmarkHoldingsQuery.data?.countries}
        data={
          broadAssetClass === FundBroadAssetClass.Equity
            ? geographyCompareEquityData
            : geographyCompareFixedIncomeData
        }
      />
    </Panel>
  );

  const { value: datePickerValue, handleChange: setPeriod } =
    useDatePickerContext();

  let timePeriod: MsTimePeriod = MsTimePeriod.ONE_YR;
  switch (datePickerValue.mode) {
    case EDateFilterValues['1YR']:
      timePeriod = MsTimePeriod.ONE_YR;
      break;
    case EDateFilterValues['3YR']:
      timePeriod = MsTimePeriod.THREE_YRS;
      break;
    case EDateFilterValues['5YR']:
      timePeriod = MsTimePeriod.FIVE_YRS;
      break;
  }

  const allowedPeriodValues = [
    EDateFilterValues['1YR'],
    EDateFilterValues['3YR'],
    EDateFilterValues['5YR'],
  ];

  const allowedPeriodsForDropdown = DATE_PERIOD_FILTER.filter(p =>
    allowedPeriodValues.some(apv => apv === p.value),
  );

  const period = useMemo(() => {
    if (allowedPeriodValues.includes(datePickerValue.mode)) {
      return datePickerValue.mode;
    }
    return EDateFilterValues['1YR'];
  }, [datePickerValue]);

  const renderComparePanelSector = (
    broadAssetClass: FundBroadAssetClass,
    title: FundTitleLabel,
  ) => {
    return (
      <Panel
        showArrow={true}
        className="bg-white overflow-hidden"
        key="sectors"
        header={
          <div className="flex flex-col sm:flex-row justify-between sm:items-center pl-4 py-4 pr-10">
            <h3 className="pb-1 sm:pb-0">
              {isDataSourcePadi ? FundTitleLabel.SECTORS : title}
            </h3>
            <>
              {!isDataSourcePadi &&
                broadAssetClassType === FundBroadAssetClass.Equity && (
                  <SectorEquityLegend className="gap-2" />
                )}
            </>
          </div>
        }
      >
        <CompareSectors
          funds={validFundQueriesData}
          showIndexColumn={showIndexColumn}
          data={
            broadAssetClass === FundBroadAssetClass.Equity
              ? sectorsEquityCompareData
              : sectorsFixedIncomeCompareData
          }
          isEquity={broadAssetClass === FundBroadAssetClass.Equity}
          benchmarkSectors={benchmarkHoldingsQuery.data?.sectors}
          loading={isSomethingLoading}
          isDataSourcePadi={isDataSourcePadi}
        />
      </Panel>
    );
  };

  return (
    <BasePageWithMetadata
      title={`Compare ${validFundQueriesData
        .map(f => extractFundTitle(f))
        .join(' vs ')}`}
    >
      <div>
        <div className={cx(styles['header-container'])}>
          <div className={cx(styles['header-sub-container'])}>
            <SectionBackButton
              previousLabel={windowWidth >= BREAKPOINTS.MD ? 'Back' : ''}
            />
          </div>
          <div className="flex justify-between px-5 pb-6">
            <h1 className="text-darkest text-xl font-bold">Compare</h1>
            {windowWidth < BREAKPOINTS.LG ? (
              <div className={styles['action-container']}>
                <Loader
                  width="90px"
                  loading={isSomethingLoading}
                  component={
                    <>
                      <Button
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                        className={styles['edit-button']}
                        icon={
                          <EditIcon className={styles['edit-icon-mobile']} />
                        }
                        type="primary"
                      >
                        <span className={styles['edit-button-mobile']}>
                          Edit
                        </span>
                      </Button>
                      <CompareFundSelectModal
                        isOpen={isModalOpen}
                        setIsOpen={setIsModalOpen}
                        initialFunds={validFundQueriesData.map(
                          f => f.shareClassDetails,
                        )}
                        initialBenchmarkName={matchingBenchmark?.secId}
                      />
                    </>
                  }
                />
              </div>
            ) : (
              <div className="flex">
                <Button
                  onClick={() => {
                    setActiveKeys([...panelKeys]);
                  }}
                  className={styles['expand-collapse-button']}
                >
                  <span>Expand All</span>
                </Button>
                <Button
                  onClick={() => {
                    setActiveKeys([]);
                  }}
                  className={cx('ml-2', styles['expand-collapse-button'])}
                >
                  <span>Collapse All</span>
                </Button>
              </div>
            )}
          </div>
          <div className={cx('bg-white py-1', styles['header-sub-container'])}>
            <div className={cx('px-5 w-full overflow-hidden', styles['title'])}>
              <div>
                <Loader
                  width="300px"
                  loading={isSomethingLoading}
                  // We don't expect the below to show up anyway so not worried about undefined being returned
                  component={
                    <div className={styles['header-sub-container--tooltip']}>
                      <h1 className="w-full text-l font-bold text-darkest">
                        {validFundQueriesData.map((f, idx, arr) => (
                          <>
                            <Tooltip
                              title={<>{extractFundTitle(f)}</>}
                              placement="bottomRight"
                              color="#3E414B"
                              overlayClassName="md:max-w-none lg:whitespace-nowrap"
                              overlayInnerStyle={{
                                width: 'fit-content',
                              }}
                            >
                              <Link
                                to={buildFundDetailsPath(
                                  f.shareClassDetails.isin,
                                )}
                                className={cx(
                                  styles['fund-link'],
                                  validFundQueriesData.length === 2 &&
                                    styles['fund-link-two-funds'],
                                )}
                              >
                                {extractFundTitle(f)}
                              </Link>
                            </Tooltip>
                            {idx !== arr.length - 1 && (
                              <>
                                <span className="text-neutral-100 px-2">
                                  {' '}
                                  vs.{' '}
                                </span>{' '}
                              </>
                            )}
                          </>
                        ))}
                      </h1>
                      <div className="m-2 !w-6 !h-6">
                        <Tooltip
                          title={
                            <>
                              Portfolios as at: <br />
                              {validFundQueriesData.map((f, idx) => (
                                <>
                                  {extractFundTitle(f)} - {fundDates[idx]}{' '}
                                  <br />
                                </>
                              ))}
                            </>
                          }
                          placement="bottomRight"
                          color="#3E414B"
                          overlayClassName="md:max-w-none lg:whitespace-nowrap"
                          overlayInnerStyle={{
                            width: 'fit-content',
                          }}
                          arrow={{ pointAtCenter: true }}
                          onOpenChange={setTooltipVisible}
                        >
                          <InfoIcon
                            className={cx('icon text-neutral', {
                              'text-neutral-100': tooltipVisible,
                            })}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                />
                <h2 className="text-xs text-regular">
                  {matchingBenchmark?.name}
                </h2>
              </div>
            </div>

            <div className="flex items-center gap-x-6">
              {windowWidth >= BREAKPOINTS.LG && (
                <div className={cx('pr-5', styles['action-container'])}>
                  <Loader
                    width="90px"
                    loading={isSomethingLoading}
                    component={
                      <>
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsModalOpen(true);
                          }}
                          className={styles['edit-button']}
                          icon={<EditIcon />}
                        >
                          <span className="hidden">Edit</span>
                        </Button>
                        <CompareFundSelectModal
                          isOpen={isModalOpen}
                          setIsOpen={setIsModalOpen}
                          initialFunds={validFundQueriesData.map(
                            f => f.shareClassDetails,
                          )}
                          initialBenchmarkName={matchingBenchmark?.secId}
                        />
                      </>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {windowWidth < BREAKPOINTS.LG && (
          <div className="flex justify-end px-5 pt-3">
            <Button
              onClick={() => {
                setActiveKeys([...panelKeys]);
              }}
              className={cx('text-neutral', styles['expand-collapse-button'])}
            >
              <span>Expand All</span>
            </Button>
            <Button
              onClick={() => {
                setActiveKeys([]);
              }}
              className={cx(
                'text-neutral ml-2',
                styles['expand-collapse-button'],
              )}
            >
              <span>Collapse All</span>
            </Button>
          </div>
        )}

        {/* CONTENT */}
        <RipsReturnTypeProvider>
          <div className={cx(styles['compare-content-wrapper'])}>
            <div
              className={cx(
                styles['compare-tool-table-container'],
                'grid gap-y-4 md:gap-y-6 lg:px-5 overflow-auto xl:overflow-hidden',
              )}
            >
              {/* First column */}
              <div>
                <CompareCollapse
                  activeKey={activeKeys}
                  onChange={handleCollapseChange}
                >
                  <Panel
                    showArrow={true}
                    className="bg-white overflow-hidden"
                    key="fundBasics"
                    header={<h3 className="py-4 pl-4">Fund Basics</h3>}
                  >
                    <CompareFundBasics
                      funds={validFundQueriesData}
                      loading={isSomethingLoading}
                    />
                  </Panel>
                  {!isFixedIncome && (
                    <Panel
                      showArrow={true}
                      className="bg-white overflow-hidden"
                      key="holdings"
                      header={<h3 className="py-4 pl-4">Top 10 Holdings</h3>}
                    >
                      <CompareHoldings
                        data={holdingsCompareData}
                        funds={validFundQueriesData}
                        showIndexColumn={showIndexColumn}
                        loading={isSomethingLoading}
                        benchmarkHoldings={
                          benchmarkHoldingsQuery.data?.holdings
                        }
                      />
                    </Panel>
                  )}

                  {isEquitySectorAndDataSourceValid &&
                    renderComparePanelSector(
                      FundBroadAssetClass.Equity,
                      FundTitleLabel.SECTORS_EQ,
                    )}

                  {isEquityGeographyAndDataSourceValid &&
                    renderComparePanelGeography(
                      FundBroadAssetClass.Equity,
                      FundTitleLabel.GEOGRAPHY_EQ,
                    )}

                  {(useEquityTemplate ||
                    (isNotEquityOrFI && hasMarketCapsLongData)) && (
                    <Panel
                      showArrow={true}
                      className="bg-white overflow-hidden"
                      key="market-cap"
                      header={<h3 className="py-4 pl-4">Market Cap</h3>}
                    >
                      <CompareMarketCap
                        data={marketCapCompareData}
                        funds={validFundQueriesData}
                        loading={isSomethingLoading}
                      />
                    </Panel>
                  )}

                  {isFixedIncomeSectorAndDataSourceValid &&
                    renderComparePanelSector(
                      FundBroadAssetClass.FixedIncome,
                      FundTitleLabel.SECTORS_FI,
                    )}

                  {isFixedIncomeGeographyAndDataSourceValid &&
                    renderComparePanelGeography(
                      FundBroadAssetClass.FixedIncome,
                      FundTitleLabel.GEOGRAPHY_FI,
                    )}

                  {isMaturityDataSourceValid && (
                    <Panel
                      showArrow={true}
                      className="bg-white overflow-hidden"
                      key="maturity"
                      header={<h3 className="py-4 pl-4">Maturity</h3>}
                    >
                      <CompareMaturity
                        funds={validFundQueriesData}
                        loading={isSomethingLoading}
                      />
                    </Panel>
                  )}

                  {isCreditQualityDataSourceValid && (
                    <Panel
                      showArrow={true}
                      className="bg-white overflow-hidden"
                      key="credit-quality"
                      header={<h3 className="py-4 pl-4">Credit Quality</h3>}
                    >
                      <CompareCreditQuality
                        funds={validFundQueriesData}
                        loading={isSomethingLoading}
                      />
                    </Panel>
                  )}
                  <Panel
                    showArrow={true}
                    className={`bg-white overflow-hidden with-extra-header ${
                      windowWidth < BREAKPOINTS.SM ? 'collapse-move-arrow' : ''
                    }`}
                    key="absolute-chart"
                    header={
                      <div className="px-4 sm:pr-10 sm:pl-4 py-0.5">
                        <AbsoluteChartTitle
                          hasInvestmentTrust={hasInvestmentTrust}
                          showDropdown={true}
                          padDropdownOnMobile={true}
                        />
                      </div>
                    }
                  >
                    {orderedFunds.length >= 2 && (
                      <div className="p-4 pt-0">
                        <div className={styles['chart-wrapper']}>
                          <AbsoluteChartV2
                            isins={isinsForChartRequest}
                            benchmarkId={benchmarkSecId}
                            hasInvestmentTrust={hasInvestmentTrust}
                            showDropdown={true}
                            retainSortOrder={true}
                            scrollable={true}
                          />
                        </div>
                      </div>
                    )}
                  </Panel>
                  {useEquityTemplate &&
                    benchmarkSecId &&
                    orderedFunds.length >= 2 &&
                    isinsForChartRequest.length !== 0 && (
                      <Panel
                        showArrow={true}
                        className={`bg-white overflow-hidden ${
                          windowWidth < BREAKPOINTS.SM
                            ? 'collapse-move-arrow'
                            : ''
                        }`}
                        key="relative-chart"
                        header={
                          <div className="px-4 sm:pr-10 sm:pl-4 py-0.5">
                            <RelativeChartTitle
                              hasInvestmentTrust={hasInvestmentTrust}
                              benchmarkName={matchingBenchmark?.name}
                              showDropdown={true}
                              padDropdownOnMobile={true}
                            />
                          </div>
                        }
                      >
                        {orderedFunds.length >= 2 && (
                          <div className="p-4 pr-0 pt-0 overflow-scroll collapse-move-arrow">
                            <div className={styles['chart-wrapper']}>
                              <RelativeChartV2
                                isins={isinsForChartRequest}
                                benchmarkId={benchmarkSecId}
                                hasInvestmentTrust={hasInvestmentTrust}
                                showDropdown={true}
                                retainSortOrder={true}
                                scrollable={true}
                              />
                            </div>
                          </div>
                        )}
                      </Panel>
                    )}

                  {!!validFundQueriesData.length && (
                    <>
                      <Panel
                        showArrow={true}
                        className="bg-white overflow-hidden"
                        key="annualised-performance"
                        header={
                          <h3 className="py-4 pl-4">Annualised Performance</h3>
                        }
                      >
                        <CompareAnnualisedPerformance
                          funds={validFundQueriesData}
                          benchmarkData={benchmarkQuery.data ?? undefined}
                          loading={isSomethingLoading}
                        />
                      </Panel>
                      <Panel
                        showArrow={true}
                        className="bg-white overflow-hidden"
                        key="calendar-performance"
                        header={
                          <h3 className="py-4 pl-4">Calendar Performance</h3>
                        }
                      >
                        <CompareCalendarYearPerformance
                          funds={validFundQueriesData}
                          benchmarkData={benchmarkQuery.data ?? undefined}
                          loading={isSomethingLoading}
                        />
                      </Panel>
                      <Panel
                        showArrow={true}
                        className={`bg-white rounded-none lg:rounded-lg overflow-hidden with-extra-header ${
                          windowWidth < BREAKPOINTS.SM
                            ? 'collapse-move-arrow'
                            : ''
                        }`}
                        key="risk"
                        header={
                          <div className="flex flex-col sm:flex-row justify-between sm:items-center py-3 px-4 sm:pl-4 sm:pr-10">
                            <h3 className="pb-1 sm:pb-0">
                              Risk <br />
                              <span className="font-normal text-neutral">
                                vs. category benchmark
                              </span>
                            </h3>
                            <div onClick={e => e.stopPropagation()}>
                              <Dropdown
                                rounded
                                label="Select value"
                                value={period}
                                onSelect={(
                                  selectedValue: EDateFilterValues,
                                ) => {
                                  setPeriod(
                                    parseDateValue(selectedValue),
                                    selectedValue,
                                  );
                                }}
                                items={allowedPeriodsForDropdown}
                                defaultValue={period}
                              />
                            </div>
                          </div>
                        }
                      >
                        <CompareRisk
                          funds={validFundQueriesData}
                          loading={isSomethingLoading}
                          dateRange={timePeriod}
                        />
                      </Panel>
                    </>
                  )}
                </CompareCollapse>
              </div>
            </div>
          </div>
        </RipsReturnTypeProvider>
      </div>
    </BasePageWithMetadata>
  );
};

export default CompareFundPage;
