import { InputRef, Tooltip } from 'antd';
import cx from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as IconError } from '@/assets/svg/icons/icon-error-v3.svg';
import { Input } from '@/components';
import useAutoFocusRef from '@/hooks/useAutoFocusRef';

import style from './style.module.less';

interface EditableInputColumnProps<T> {
  id: string;
  onBlurEvent: (value: number) => void;
  onEnterKeyDown: (value: number) => void;
  value?: T;
  disableEdit?: boolean;
  isInvalid?: boolean;
  isInvalidTooltip?: string;
}

const EditableNumberInputColumn: React.FCWithChild<
  EditableInputColumnProps<number>
> = ({
  id,
  value,
  onEnterKeyDown,
  onBlurEvent,
  disableEdit,
  isInvalid,
  isInvalidTooltip,
}) => {
  const [isInputShown, setIsInputShown] = useState(false);
  const [inputValue, setInputValue] = useState(value?.toString() ?? '');
  const toggleInputShow = () => {
    !disableEdit && setIsInputShown(!isInputShown);
  };

  const inputRef = useAutoFocusRef<InputRef>([], isInputShown);

  const checkValue = (value: string) => {
    if (value.indexOf('-') > -1) {
      return false;
    }
    const regex = /^\d{0,3}(\.\d{0,3})?$/;
    if ((regex.test(value) || value === '') && +value >= 0) {
      return true;
    }
    return false;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (checkValue(value)) {
      setInputValue(value);
    }
  };

  return (
    <div
      data-id="FOO"
      className={cx(
        style['col-wrapper'],
        style['editable-cell-root'],
        isInvalid && 'bg-[#FFF8F2]',
        'grid grid-cols-[1fr_min-content] items-center min-h-12',
      )}
    >
      {isInputShown ? (
        <div className={cx(style['input-wrapper'])}>
          {/* Cannot replace with CustomInput - it has a lot of validation and number related logic
            we must put more effort to apply it to CustomInput and make sure styling is not modified
          */}
          <Input
            autoFocus
            ref={inputRef}
            id={`input-${id}`}
            name={`input-${id}`}
            className={cx(
              style['editable-input'],
              'px-2 py-1 ml-4 mr-2 border border-primary rounded max-w-30',
            )}
            placeholder="0.000 %"
            step={0.001}
            type="text"
            inputMode="decimal"
            pattern="/^\d{0,3}(\.\d{0,3})?$/"
            min={0}
            max={100}
            onChange={handleInputChange}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              const val = +(e.currentTarget.value || 0);
              if ((value || 0) === val) {
                toggleInputShow();
                return;
              }
              onBlurEvent(val);
              setIsInputShown(false);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (!checkValue(e.currentTarget.value)) {
                return;
              }
              const val = +(e.currentTarget.value || 0);
              if (e.key === 'Enter') {
                setIsInputShown(false);
                if ((value || 0) === val) {
                  return;
                }
                onEnterKeyDown(val);
              }
            }}
            value={inputValue}
          />
        </div>
      ) : (
        <button
          data-test-id={id}
          onClick={toggleInputShow}
          className={cx(
            style['btn-edit-value'],
            !value && style['btn-edit-zero-value'],
            'px-6',
          )}
        >
          <span>{value ? `${value} %` : ''}</span>
        </button>
      )}
      {isInvalid && (
        <Tooltip title={isInvalidTooltip} placement="bottom" className="mr-2">
          <IconError className="w-4 h-4 text-danger" />
        </Tooltip>
      )}
    </div>
  );
};

export default EditableNumberInputColumn;
