import {
  Portfolio,
  PortfolioFund,
  PortfolioFundForUpdate,
  PortfolioWeighting,
} from '@aminsights/contract';
import {
  buildFundDetailsPath,
  calculatePortfolioTotalForDate,
  DATE_FORMAT_DASHED,
} from '@aminsights/shared';
import { DISPLAY_DATE_FORMAT } from '@aminsights/shared';
import { Checkbox, Skeleton } from 'antd';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as AddItem } from '@/assets/svg/icons/icon-add-item.svg';
import { ReactComponent as IconError } from '@/assets/svg/icons/icon-error-v3.svg';
import { Tooltip } from '@/components';
import { IDataTableColumns } from '@/components/Table/DataTable';
import { PORTFOLIO_EDGE_DATES } from '@/hooks/query-hooks/portfolio-hooks/query-keys';
import { PortfolioEdgeDates } from '@/hooks/query-hooks/portfolio-hooks/usePortfolioEdgeDates';
import EditableNumberInputColumn from '@/partials/columns/EditableColumns/EditableNumberInputColumn';
import PortfolioFundHeaderDateSelect from '@/partials/columns/HeaderElements/PortfolioFundOptions/PortfolioFundHeaderDateSelect';
import PortfolioFundHeaderOptions from '@/partials/columns/HeaderElements/PortfolioFundOptions/PortfolioFundHeaderOptions';
import queryClient from '@/queryClient';
import { getLatestDateForNewPortfolioColumn } from '@/utils/date';

import style from '../style.module.less';
import AddFundSearchBox from './AddFundSearchBox';
import {
  UpdateDateInPortfolioFundAction,
  UpdateWeightingForFundInPortfolioAction,
} from './types';
import { onEnterKeyDown, onFocusEvent, sortPortolioDates } from './utilities';

export const generatePortfolioHoldingFooter = ({
  portfolioFunds,
  showActionCells = true,
  enablePadding = true,
}: {
  portfolioFunds: PortfolioFundForUpdate[];
  showActionCells?: boolean;
  enablePadding?: boolean;
}) => {
  if (!portfolioFunds.some(p => p.isin !== '')) {
    return [];
  }
  const portfolioHoldingsFooter: IDataTableColumns[] = [
    ...(showActionCells
      ? [{ title: '', idSubText: 'tick', renderType: 'text' as const }]
      : []),
    {
      title: 'Total',
      idSubText: 'fund-name',
      isColumnFixed: true,
      renderType: 'text' as const,
    },
    ...(showActionCells
      ? [{ title: '', idSubText: 'add', renderType: 'text' as const }]
      : []),
  ];
  const allDates = new Map<string, boolean>();

  const allDatesWithDuplicates = portfolioFunds
    .flatMap(pF =>
      pF.weightings.map(w => ({ date: w.date, isValid: w.isValid })),
    )
    .sort(sortPortolioDates);

  allDatesWithDuplicates.forEach(d => {
    allDates.set(d.date, d.isValid ?? false);
  });

  if (allDates.size === 0) {
    portfolioHoldingsFooter.push({
      title: '',
      idSubText: 'emptyAfterAddDate',
      renderType: 'text',
    });
  } else {
    allDates.forEach((isValid, date) => {
      const totalForTheDate = calculatePortfolioTotalForDate(
        date,
        portfolioFunds.flatMap(pF => pF.weightings),
      );

      portfolioHoldingsFooter.push({
        title: (
          <span
            className={cx({ 'inline-block': enablePadding })}
            style={{
              color: !isValid ? '#E64236' : 'inherit',
            }}
          >
            {isValid && totalForTheDate === 0 ? '' : `${totalForTheDate} %`}
          </span>
        ),
        renderType: 'text',
        align: 'left',
        idSubText: `${date}-holdings-column`,
      });
    });
  }
  return portfolioHoldingsFooter;
};

const generateDynamicDatesColumns = (
  validatedDates: Map<string, boolean>,
  portfolioFunds: PortfolioFund[],
  updateWeightingForFundInPortfolio: UpdateWeightingForFundInPortfolioAction,
  updateDateInPortfolioFund: UpdateDateInPortfolioFundAction,
  deleteDateFromPortfolio: (portfolioId: string, date: string) => void,
  currentPortfolio: Portfolio,
  disableEdit?: boolean,
) => {
  const result: IDataTableColumns[] = [];

  const allDates = [...validatedDates.keys()];
  validatedDates.forEach((isValid, date) => {
    const dayJsDate = dayjs(date, DATE_FORMAT_DASHED);
    const dateValue = getLatestDateForNewPortfolioColumn(allDates, dayJsDate);

    const generatedDateColumn: IDataTableColumns = {
      idSubText: `${date}-holdings-column`,
      className: cx(
        !dayJsDate.isValid() ? style['active-item'] : undefined,
        style['date-select-container'],
      ),
      title: (
        <PortfolioFundHeaderDateSelect
          date={dayJsDate}
          disabledDates={[...allDates]}
          isValid={isValid}
          onUpdateDate={(oldDate: Dayjs, newDate: Dayjs) => {
            const disabledDates = portfolioFunds.flatMap(f =>
              f.weightings.map(w => w.date),
            );
            const newDateFormatted = newDate.format(DATE_FORMAT_DASHED);
            if (disabledDates.some(d => d === newDateFormatted)) {
              return false;
            }
            updateDateInPortfolioFund(
              currentPortfolio._id,
              oldDate.isValid() ? oldDate.format(DATE_FORMAT_DASHED) : '',
              newDateFormatted,
            );
            return true;
          }}
          disableEdit={disableEdit}
        >
          <Tooltip
            placement="bottom"
            title={
              isValid ? undefined : (
                <span className="text-xs md:whitespace-nowrap">
                  Total needs to be a 100%
                </span>
              )
            }
          >
            {dateValue.format(DISPLAY_DATE_FORMAT)}
          </Tooltip>
        </PortfolioFundHeaderDateSelect>
      ),
      headerElements: (_: PortfolioFund): React.ReactNode => {
        return (
          <PortfolioFundHeaderOptions
            date={dayJsDate}
            disabledDates={[...allDates]}
            onDeleteDate={async (date: Dayjs) => {
              deleteDateFromPortfolio(
                currentPortfolio._id,
                date.format(DATE_FORMAT_DASHED),
              );
            }}
            //This logic is still wip - to show calendar for new dates
            onUpdateDate={(oldDate: Dayjs, newDate: Dayjs) => {
              const disabledDates = portfolioFunds.flatMap(f =>
                f.weightings.map(w => w.date),
              );
              const newDateFormatted = newDate.format(DATE_FORMAT_DASHED);
              if (disabledDates.some(d => d === newDateFormatted)) {
                return false;
              }
              updateDateInPortfolioFund(
                currentPortfolio._id,
                oldDate.isValid() ? oldDate.format(DATE_FORMAT_DASHED) : '',
                newDateFormatted,
              );
              return true;
            }}
            disableEidt={disableEdit}
          />
        );
      },
      render: (item: PortfolioFund): React.ReactNode => {
        const weightingOfFundOnTheDate = item.weightings.find(
          (w: PortfolioWeighting) => w.date === date,
        );
        const weightingValue =
          weightingOfFundOnTheDate?.value !== 0
            ? weightingOfFundOnTheDate?.value
            : undefined;

        const data = queryClient.getQueryData<PortfolioEdgeDates>([
          PORTFOLIO_EDGE_DATES,
          { portfolioId: currentPortfolio._id },
        ]);

        const portfolioEdgeDates = data?.[item.isin];

        const columnDate = dayjs(date, 'DD-MM-YYYY');

        const weightingDateIsTooFarInThePast = dayjs(
          portfolioEdgeDates?.startDate?.date,
          'YYYY-MM-DD',
        ).isAfter(columnDate);

        const weightingDateIsPastLastDate = dayjs(
          portfolioEdgeDates?.endDate?.date,
          'YYYY-MM-DD',
        ).isBefore(columnDate);

        const isInvalidTooltipMessage = 'No data during this time period';

        const hasNoEdgeDates =
          data?.[item.isin] &&
          portfolioEdgeDates?.startDate === undefined &&
          portfolioEdgeDates?.endDate === undefined;

        return (
          <EditableNumberInputColumn
            id={date + item.isin}
            value={weightingValue}
            onBlurEvent={(value: number) => {
              return onFocusEvent(
                value,
                item,
                date,
                updateWeightingForFundInPortfolio,
                currentPortfolio,
              );
            }}
            onEnterKeyDown={(value: number) => {
              return onEnterKeyDown(
                value,
                item,
                date,
                updateWeightingForFundInPortfolio,
                currentPortfolio,
              );
            }}
            disableEdit={disableEdit}
            isInvalid={
              (portfolioEdgeDates &&
                !!weightingValue &&
                (weightingDateIsTooFarInThePast ||
                  weightingDateIsPastLastDate)) ||
              hasNoEdgeDates
            }
            isInvalidTooltip={isInvalidTooltipMessage}
          />
        );
      },
      renderType: 'text',
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2, width: 200 }}
          active
        />
      ),
    };
    result.push(generatedDateColumn);
  });

  return result;
};

export const generatePortfolioHoldingColumns = (
  currentPortfolio: Portfolio,
  markedFundsForDelete: string[],
  portfolioFunds: PortfolioFund[],
  addFundToPortfolioCall: (
    isin: string,
    fundName: string,
    code: string,
    broadAssetClass: string,
  ) => void,
  updateWeightingForFundInPortfolio: UpdateWeightingForFundInPortfolioAction,
  addDate: () => void,
  updateDateInPortfolioFund: UpdateDateInPortfolioFundAction,
  deleteDateFromPortfolio: (portfolioId: string, date: string) => void,
  markFundForDelete: (isin: string) => void,
  toggleAllFundsForDelete: (disableAll: boolean) => void,
  removeRowWithoutData: () => void,
  hasManagePermissions: boolean,
  isMobile: boolean,
) => {
  const portfolioHoldingsColumns: IDataTableColumns[] = [];

  const tickBoxColumn = {
    title: '',
    align: 'center',
    width: isMobile ? 38 : 50,
    headerElements: (_: PortfolioFund): React.ReactNode => {
      const savedFunds = portfolioFunds.filter(f => f.isin !== '');
      return (
        savedFunds.length !== 0 && (
          <Checkbox
            checked={
              savedFunds.length > 0 &&
              savedFunds.every(pF =>
                markedFundsForDelete.some(f => f === pF.isin),
              )
            }
            onChange={() => {
              toggleAllFundsForDelete(
                portfolioFunds
                  .filter(f => f.isin !== '')
                  .every(pF => markedFundsForDelete.some(f => f === pF.isin)),
              );
            }}
          />
        )
      );
    },
    render: (item: PortfolioFund): React.ReactNode => {
      return item.isin ? (
        <Checkbox
          className="px-4"
          checked={markedFundsForDelete.some(f => f === item.isin)}
          onChange={() => {
            markFundForDelete(item.isin);
          }}
        />
      ) : (
        <p></p>
      );
    },
    renderType: 'text' as const,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2, width: 10 }}
        active
      />
    ),
  };
  if (hasManagePermissions) {
    portfolioHoldingsColumns.push(tickBoxColumn);
  }

  const closeFundSearch = () => {
    removeRowWithoutData();
  };

  const alreadyAttachedFunds = portfolioFunds.map(p => ({
    isin: p.isin,
    broadAssetClass: p.broadAssetClass,
    fundName: p.shareClassDetails?.code ?? '', // Since enableSearchByShareClass is enabled
  }));

  const columnFundOrInvTrust = {
    title: 'Fund/Investment Trust',
    width: isMobile ? 150 : 350,
    renderType: 'text' as const,
    render: (item: PortfolioFund) => {
      const edgeDatesQueryData = queryClient.getQueryState<PortfolioEdgeDates>([
        PORTFOLIO_EDGE_DATES,
        { portfolioId: currentPortfolio._id },
      ]);

      const data = edgeDatesQueryData?.data;
      const startDate = dayjs(data?.[item.isin]?.startDate?.date, 'YYYY-MM-DD');
      const endDate = dayjs(data?.[item.isin]?.endDate?.date, 'YYYY-MM-DD');

      const isFetchingAndNoData =
        edgeDatesQueryData?.fetchStatus === 'fetching' && !data?.[item.isin];

      // Initialize vairables
      let startWeightIsWithinRange = true;
      let endWeightIsWithinRange = true;
      let areWeightingsWithinRange = true;
      let toolTipMessage = '';

      // Need to sort it to make sure
      const weightingDates = item.weightings
        .filter(w => w.value) // Only do validation on weightings with value
        .map(w => w.date);

      if (
        startDate.isValid() &&
        endDate.isValid() &&
        weightingDates.length > 0
      ) {
        const sortedWeightingDates = weightingDates.sort((a, b) =>
          dayjs(a, 'DD-MM-YYYY').isBefore(dayjs(b, 'DD-MM-YYYY')) ? -1 : 1,
        );
        const parsedDates = sortedWeightingDates.map(d =>
          dayjs(d, 'DD-MM-YYYY'),
        );
        const firstDateWithWeightings = parsedDates[0];
        const lastDateWithWeightings = parsedDates[parsedDates.length - 1];

        startWeightIsWithinRange =
          startDate.isBefore(firstDateWithWeightings) ||
          startDate.isSame(firstDateWithWeightings);

        endWeightIsWithinRange =
          endDate.isAfter(lastDateWithWeightings) ||
          endDate.isSame(lastDateWithWeightings);

        areWeightingsWithinRange = data
          ? startWeightIsWithinRange && endWeightIsWithinRange
          : true;

        toolTipMessage = `Fund active period: ${startDate.format(
          DISPLAY_DATE_FORMAT,
        )} to ${endDate.format(DISPLAY_DATE_FORMAT)}`;
      } else if (data && !startDate.isValid() && !endDate.isValid()) {
        areWeightingsWithinRange = false;
        toolTipMessage = 'No chart data for this fund';
      }

      const showError =
        !areWeightingsWithinRange && !isFetchingAndNoData && data?.[item.isin];
      const [tooltipVisible, setTooltipVisible] = useState(false);
      return !!item.isin && !!item.fundName ? (
        <div
          className={cx(
            'h-full w-full min-h-12 px-4',
            'grid grid-cols-[1fr_min-content] items-center font-bold',
            // !areWeightingsWithinRange && 'bg-[#FFF8F2]',
            showError && 'text-danger',
          )}
        >
          <Link to={buildFundDetailsPath(item.isin)}>{item.fundName}</Link>
          {showError && (
            <Tooltip
              title={toolTipMessage}
              overlayClassName="max-w-max"
              overlayInnerStyle={{ whiteSpace: 'nowrap' }}
              placement="bottom"
              visible={tooltipVisible}
              onOpenChange={setTooltipVisible}
            >
              <IconError
                className={cx('w-4 h-4 text-danger mx-2', {
                  'text-destructive-400': tooltipVisible,
                })}
              />
            </Tooltip>
          )}
        </div>
      ) : (
        <div className="w-full p-4">
          <AddFundSearchBox
            onAdd={addFundToPortfolioCall}
            onClose={closeFundSearch}
            fundsInPortfolio={alreadyAttachedFunds}
            dataTestId="addFundSearchBox"
          />
        </div>
      );
    },
    isColumnFixed: true,
    loader: (
      <Skeleton
        avatar={{ size: 'small' }}
        title={false}
        paragraph={{ rows: 2, width: 200 }}
        active
      />
    ),
  };
  portfolioHoldingsColumns.push(columnFundOrInvTrust);

  //Show these columns only for a portfolio, but not for an empty page(no portfolios created)
  if (portfolioFunds.filter(p => !!p.isin).length > 0) {
    const allDates = new Map<string, boolean>();
    const allDatesWithDuplicates = portfolioFunds
      .flatMap(pF =>
        pF.weightings.map(w => ({
          date: w.date,
          isValid: w.isValid ?? false,
        })),
      )
      .sort(sortPortolioDates);

    allDatesWithDuplicates.forEach(d => {
      allDates.set(d.date, d.isValid ?? false);
    });

    const addDateColumn: IDataTableColumns = {
      title: '',
      align: 'center',
      tooltipText: (
        <span className="text-xs md:whitespace-nowrap">Add Column</span>
      ),
      renderType: 'text',
      width: isMobile ? 45 : 56,
      idSubText: 'addDate',
      regenerateColumnsClick: () => {
        addDate();
      },
      className: cx('h-full cursor-pointer', style['hover-modifier']),
      headerElements: (_: PortfolioFund): React.ReactNode => {
        return (
          <button data-test-id="addDate">
            <AddItem
              width={15}
              fill="#007FFF"
              className="self-center w-6 h-6 h-full p-1 text-sm rounded"
            />
          </button>
        );
      },
      render: (_: PortfolioFund): React.ReactNode => <div></div>,
    };

    if (hasManagePermissions) {
      portfolioHoldingsColumns.push(addDateColumn);
    }

    if (allDates.size === 0) {
      const emptyColumnAfterDate = {
        title: '',
        align: 'center',
        idSubText: 'afterAddDate',
        renderType: 'text' as const,
        render: (_: PortfolioFund): React.ReactNode => <div></div>,
      };
      portfolioHoldingsColumns.push(emptyColumnAfterDate);
    } else {
      const generatedDynamicDatesColumns = generateDynamicDatesColumns(
        allDates,
        portfolioFunds,
        updateWeightingForFundInPortfolio,
        updateDateInPortfolioFund,
        deleteDateFromPortfolio,
        currentPortfolio,
        !hasManagePermissions,
      );
      portfolioHoldingsColumns.push(...generatedDynamicDatesColumns);
    }
  }

  return {
    columns: portfolioHoldingsColumns,
    footer: generatePortfolioHoldingFooter({
      portfolioFunds: portfolioFunds,
      showActionCells: hasManagePermissions,
    }),
  };
};
