// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".laFJCEjuQtRgCHkyhtpa .tpn5CN9g4NmA35gwsF6K {\n  display: flex;\n  cursor: pointer;\n  flex-direction: column;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  font-weight: 500;\n  --tw-text-opacity: 1;\n  color: rgb(49 51 65 / var(--tw-text-opacity))\n}\n@media (min-width: 576px) {\n  .laFJCEjuQtRgCHkyhtpa .tpn5CN9g4NmA35gwsF6K {\n    white-space: nowrap;\n    font-weight: 700\n  }\n}\n.fAhizu0e7ojDJtjlSN9t .ant-modal-body {\n  padding: 0px\n}\n.fAhizu0e7ojDJtjlSN9t .ant-modal-content .ant-modal-header {\n  border-style: none;\n  --tw-bg-opacity: 1;\n  background-color: rgb(255 255 255 / var(--tw-bg-opacity))\n}\n.fAhizu0e7ojDJtjlSN9t .ant-modal-content .ant-modal-header .ant-modal-title {\n  font-size: 1.125rem;\n  line-height: 1.75rem;\n  font-weight: 600;\n  --tw-text-opacity: 1;\n  color: rgb(49 51 65 / var(--tw-text-opacity))\n}\n", "",{"version":3,"sources":["webpack://./src/partials/SignificantChanges/SignificantChangesFirstColumn/style.module.less"],"names":[],"mappings":"AAEI;EAAA,aAAA;EAAA,eAAA;EAAA,sBAAA;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA;AAEA;EACE;IAAA,mBAAA;IAAA;EAAA;AAEN;AAMM;EAAA;AAAA;AAGA;EAAA,kBAAA;EAAA,kBAAA;EAAA;AAAA;AAEE;EAAA,mBAAA;EAAA,oBAAA;EAAA,gBAAA;EAAA,oBAAA;EAAA;AAAA","sourcesContent":[".fund-details {\n  .main-title {\n    @apply flex flex-col font-medium text-sm text-darkest cursor-pointer;\n\n    @media (min-width: 576px) {\n      @apply whitespace-nowrap font-bold;\n    }\n  }\n}\n\n.significant-changes-modal {\n  :global {\n    .ant-modal-body {\n      @apply p-0;\n    }\n    .ant-modal-content .ant-modal-header {\n      @apply bg-white border-none;\n      .ant-modal-title {\n        @apply text-lg text-darkest font-semibold;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fund-details": "laFJCEjuQtRgCHkyhtpa",
	"main-title": "tpn5CN9g4NmA35gwsF6K",
	"significant-changes-modal": "fAhizu0e7ojDJtjlSN9t"
};
export default ___CSS_LOADER_EXPORT___;
