import { Fund } from '@aminsights/contract';
import { EMPTY_DATA_POINT } from '@aminsights/shared';
import { Skeleton } from 'antd';
import React from 'react';

import { IDataTableColumns } from '@/components/Table/DataTable';
import useIsMobileView from '@/utils/useIsMobileView';

import FundInvestmentTrustColumn from './FundInvestmentTrustColumn';

const GenderPayGap = (isInExplorePage = false): IDataTableColumns[] => {
  const isMobile = useIsMobileView();

  return [
    {
      title: 'Fund/Investment Trust',
      sortKey: isInExplorePage ? 'shareClassDetails.code' : 'fundName',
      idSubText: 'fundName',
      render: (item: Fund): React.ReactNode => (
        <FundInvestmentTrustColumn
          data={{
            id: item.shareClassDetails.isin,
            name: item.shareClassDetails.code,
            type: item.legalStructure,
            assetClassCode: item.assetClassCode,
            fundId: item.fundId,
            legalStructure: item.legalStructure,
          }}
        />
      ),
      renderType: 'custom',
      align: 'between',
      width: isMobile ? 192 : 246,
      isColumnFixed: true,
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2, width: 200 }}
          active
        />
      ),
    },
    {
      title: 'Hourly Wage Gap',
      idSubText: 'hourlyWageGap',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: '2023',
          sortKey: 'genderPayGap.hourlyWageGap.2023',
          idSubText: 'hourlyWageGap-0',
          render: (item: Fund) => {
            const hourlyWageGap = item.genderPayGap?.hourlyWageGap?.find(
              hwg => hwg.year === 2023,
            );
            return hourlyWageGap?.percentage
              ? `${hourlyWageGap.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
        {
          title: '2022',
          sortKey: 'genderPayGap.hourlyWageGap.2022',
          idSubText: 'hourlyWageGap-1',
          render: (item: Fund) => {
            const hourlyWageGap = item.genderPayGap?.hourlyWageGap?.find(
              hwg => hwg.year === 2022,
            );
            return hourlyWageGap?.percentage
              ? `${hourlyWageGap.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
        {
          title: '2021',
          sortKey: 'genderPayGap.hourlyWageGap.2021',
          idSubText: 'hourlyWageGap-2',
          render: (item: Fund) => {
            const hourlyWageGap = item.genderPayGap?.hourlyWageGap?.find(
              hwg => hwg.year === 2021,
            );
            return hourlyWageGap?.percentage
              ? `${hourlyWageGap.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
      ],
    },
    {
      title: 'Bonus Pay Gap',
      idSubText: 'bonusPayGap',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: '2023',
          sortKey: 'genderPayGap.bonusPayGap.2023',
          idSubText: 'bonusPayGap-0',
          render: (item: Fund) => {
            const bonusPayGap = item.genderPayGap?.bonusPayGap?.find(
              bpg => bpg.year === 2023,
            );
            return bonusPayGap?.percentage
              ? `${bonusPayGap.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
        {
          title: '2022',
          sortKey: 'genderPayGap.bonusPayGap.2022',
          idSubText: 'bonusPayGap-1',
          render: (item: Fund) => {
            const bonusPayGap = item.genderPayGap?.bonusPayGap?.find(
              bpg => bpg.year === 2022,
            );
            return bonusPayGap?.percentage
              ? `${bonusPayGap.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
        {
          title: '2021',
          sortKey: 'genderPayGap.bonusPayGap.2021',
          idSubText: 'bonusPayGap-2',
          render: (item: Fund) => {
            const bonusPayGap = item.genderPayGap?.bonusPayGap?.find(
              bpg => bpg.year === 2021,
            );
            return bonusPayGap?.percentage
              ? `${bonusPayGap.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
      ],
    },
    {
      title: '% of Women in Highest Paid Jobs',
      idSubText: 'womenHighestPaidJobs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: '2023',
          sortKey: 'genderPayGap.womenHighestPaidJobs.2023',
          idSubText: 'womenHighestPaidJobs-0',
          render: (item: Fund) => {
            const womenHighestPaidJobs =
              item.genderPayGap?.womenHighestPaidJobs?.find(
                whpj => whpj.year === 2023,
              );
            return womenHighestPaidJobs?.percentage
              ? `${womenHighestPaidJobs.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
        {
          title: '2022',
          sortKey: 'genderPayGap.womenHighestPaidJobs.2022',
          idSubText: 'womenHighestPaidJobs-1',
          render: (item: Fund) => {
            const womenHighestPaidJobs =
              item.genderPayGap?.womenHighestPaidJobs?.find(
                whpj => whpj.year === 2022,
              );
            return womenHighestPaidJobs?.percentage
              ? `${womenHighestPaidJobs.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
        {
          title: '2021',
          sortKey: 'genderPayGap.womenHighestPaidJobs.2021',
          idSubText: 'womenHighestPaidJobs-2',
          render: (item: Fund) => {
            const womenHighestPaidJobs =
              item.genderPayGap?.womenHighestPaidJobs?.find(
                whpj => whpj.year === 2021,
              );
            return womenHighestPaidJobs?.percentage
              ? `${womenHighestPaidJobs.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
      ],
    },
    {
      title: '% of Women in Lowest Paid Jobs',
      idSubText: 'womenLowestPaidJobs',
      renderType: 'custom',
      align: 'center',
      children: [
        {
          title: '2023',
          sortKey: 'genderPayGap.womenLowestPaidJobs.2023',
          idSubText: 'womenLowestPaidJobs-0',
          render: (item: Fund) => {
            const womenLowestPaidJobs =
              item.genderPayGap?.womenLowestPaidJobs?.find(
                wlpj => wlpj.year === 2023,
              );
            return womenLowestPaidJobs?.percentage
              ? `${womenLowestPaidJobs.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
        {
          title: '2022',
          sortKey: 'genderPayGap.womenLowestPaidJobs.2022',
          idSubText: 'womenLowestPaidJobs-1',
          render: (item: Fund) => {
            const womenLowestPaidJobs =
              item.genderPayGap?.womenLowestPaidJobs?.find(
                wlpj => wlpj.year === 2022,
              );
            return womenLowestPaidJobs?.percentage
              ? `${womenLowestPaidJobs.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
        {
          title: '2021',
          sortKey: 'genderPayGap.womenLowestPaidJobs.2021',
          idSubText: 'womenLowestPaidJobs-2',
          render: (item: Fund) => {
            const womenLowestPaidJobs =
              item.genderPayGap?.womenLowestPaidJobs?.find(
                wlpj => wlpj.year === 2021,
              );
            return womenLowestPaidJobs?.percentage
              ? `${womenLowestPaidJobs.percentage.toFixed(1)}%`
              : EMPTY_DATA_POINT;
          },
          renderType: 'number',
          width: 100,
        },
      ],
    },
  ];
};

export default GenderPayGap;
