import { Checkbox, Skeleton } from 'antd';

import { IDataTableColumns } from '@/components/Table/DataTable';
export interface IImportedFundsData {
  key: string;
  fundName: string;
  isin: string;
  category: string;
  isWatching?: boolean;
  isChecked?: boolean;
}

export const ImportedFundsDataTableColumns = (
  onCheckedRow: (isins: string[]) => void,
  selectedISINs: string[],
  isAllCheckboxChecked?: boolean,
): Array<IDataTableColumns> => {
  return [
    {
      title: '',
      width: 15,
      headerElements: (): React.ReactNode => {
        return (
          <div className="pl-2">
            <Checkbox
              onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                const isChecked = event.currentTarget.checked;
                onCheckedRow([]);
                if (isChecked) {
                  onCheckedRow(selectedISINs);
                }
              }}
              checked={isAllCheckboxChecked}
            />
          </div>
        );
      },
      render: (item: IImportedFundsData): React.ReactNode => {
        return (
          <Checkbox
            key={item.isin}
            onClick={() => onCheckedRow([item.isin])}
            checked={item.isChecked}
          />
        );
      },
      renderType: 'text',
      loader: (
        <Skeleton
          avatar={{ size: 'small' }}
          title={false}
          paragraph={{ rows: 2, width: 15 }}
          active
        />
      ),
    },
    {
      title: <span className="pl-3">Funds/Investment Trust</span>,
      dataTestIdSuffix: 'Fund/Investment Trust',
      render: (item: IImportedFundsData) => {
        return (
          <div className="text-sm text-neutral">{item?.fundName || ''}</div>
        );
      },
      renderType: 'text',
      sortKey: 'fundName',
      width: 350,
      isColumnFixed: true,
    },
    {
      title: 'ISIN',
      render: (item: IImportedFundsData) => (
        <div className="text-sm text-neutral">{item?.isin || ''}</div>
      ),
      renderType: 'number',
    },
    {
      title: 'Category',
      render: (item: IImportedFundsData) => (
        <div className="text-sm text-neutral">{item?.category || ''}</div>
      ),
      renderType: 'text',
    },
  ];
};
