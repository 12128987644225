import {
  Fund,
  PageQueryParametersSortDirectionEnum,
} from '@aminsights/contract';
import {
  buildFundDetailsPath,
  calculateTopNCurrencies,
  CurrencyToShow,
  getFundShareClassDetailsFromArray,
  LIMIT_FUNDS_FOR_CHARTING,
} from '@aminsights/shared';
import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import { DataTable, PageResults } from '@/components';
import {
  MAX_COMPARE_TOTAL,
  TABLE_UNIQUE_KEY,
  TOP_CURRENCIES_FOR_WATCHLIST,
} from '@/constants';
import { DataTableRenderedAt } from '@/constants/dataTableRenderedAt';
import useWatchlistCurrencyQuery from '@/hooks/query-hooks/watchlist-hooks/useWatchlistCurrencyQuery';
import { useCurrentBucket } from '@/hooks/query-hooks/watchlist-hooks/useWatchlists';
import useOnCheckedRow from '@/hooks/useOnCheckedRow';
import TablesActionToast from '@/partials/Toast/TablesActionToast';

import { WatchlistCurrencyColumns } from './WatchlistCurrencyColumns';

const WatchlistCurrencyTable: FC<{ isActive: boolean }> = ({ isActive }) => {
  const history = useHistory();
  const currentBucket = useCurrentBucket();
  const [sortKey, setSortKey] = useState('fundName');
  const [sortDirection, setSortDirection] =
    useState<PageQueryParametersSortDirectionEnum>(
      PageQueryParametersSortDirectionEnum.Asc,
    );
  const [currencyColumns, setCurrencyColumns] = useState<CurrencyToShow[]>([]);
  const { data: response, isLoading } = useWatchlistCurrencyQuery({
    sortKey: sortKey,
    sortDirection: sortDirection,
    bucketId: currentBucket?.id,
  });

  const checkedRowState = useOnCheckedRow<Fund>(response?.data.length || 0);
  const { checkedRows, setCheckedRows, setIsAllCheckboxChecked } =
    checkedRowState;

  useEffect(() => {
    setCheckedRows([]);
  }, [isActive]);

  useEffect(() => {
    if (!isLoading) {
      const newCurrencyColumns = calculateTopNCurrencies(
        response?.data?.map(f => f.currencyExposures ?? []).flat() || [],
        TOP_CURRENCIES_FOR_WATCHLIST,
      );
      if (newCurrencyColumns.length > 0) {
        setCurrencyColumns(newCurrencyColumns);
      } else {
        //fallback value if no fund has currency exposure
        setCurrencyColumns([
          { currencyId: 'USD' },
          { currencyId: 'GBP' },
          { currencyId: 'EUR' },
        ]);
      }
    }
  }, [response?.data, isLoading]);

  const columns = WatchlistCurrencyColumns({
    rowSelectionState: checkedRowState,
    currentBucket,
    onSelectAll: checked => {
      if (checked) {
        const topIsins = response?.data
          .slice(0, LIMIT_FUNDS_FOR_CHARTING)
          .map(item => item.shareClassDetails.isin);
        setCheckedRows(topIsins ?? []);
      } else {
        setCheckedRows([]);
      }
    },
    currencyColumns: currencyColumns.map(c => c.currencyId),
  });

  const totalIsinsPortal = document.getElementById('total-isins-portal');

  return (
    <>
      {isActive &&
        totalIsinsPortal &&
        ReactDOM.createPortal(
          <PageResults
            className={'min-w-28'}
            totalCount={response?.data.length ?? 0}
          />,
          totalIsinsPortal,
        )}
      <DataTable
        className="watchlist-tr-padding"
        uniqueKey={TABLE_UNIQUE_KEY}
        loading={isLoading}
        columns={columns}
        data={response?.data}
        onSort={async (
          key: string,
          direction: PageQueryParametersSortDirectionEnum,
        ) => {
          setSortKey(key);
          setSortDirection(direction);
        }}
        onRow={id => history.push(buildFundDetailsPath(id))}
      />
      {checkedRows.length > 0 && (
        <TablesActionToast
          isins={checkedRows}
          count={checkedRows?.length || 0}
          onClearCheckedRows={(isins: string[]) => {
            setCheckedRows(isins);
            setIsAllCheckboxChecked(false);
          }}
          checkedRowsClassDetails={checkedRows
            .slice(0, MAX_COMPARE_TOTAL)
            .map(i =>
              getFundShareClassDetailsFromArray(response?.data ?? [], i),
            )}
          dataTableRenderedAt={DataTableRenderedAt.Watchlist}
        />
      )}
    </>
  );
};

export default WatchlistCurrencyTable;
