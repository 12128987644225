import { buildFundDetailsPath } from '@aminsights/shared';
import { Tooltip } from 'antd';
import cx from 'classnames';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '@/assets/svg/icons/icon-close.svg';
import { BREAKPOINTS } from '@/constants';
import { useAppContext } from '@/context/AppContext';
import { switchTableRowColor } from '@/utils/generate-color';

import style from './style.module.less';

interface LegendCardBaseProps {
  label: string;
  tooltip: string;
  isFeatured: boolean;
  color: string;
  date: string;
  isActive?: boolean;
  onRemove?: (isin: string, benchmark?: boolean) => void;
}

export interface LegendCardProps extends LegendCardBaseProps {
  isIndex?: boolean;
  idForFundDetailsLink?: string; // If it is present then wrap the card in <a></a>
  id?: string;
  superLabel?: string;
  targetLink?: string;
}

export interface BaseCardDetails extends LegendCardBaseProps {
  id: string;
  isBenchmark: boolean;
  fundId?: string;
  isin?: string;
}

const LegendCard: React.FCWithChild<LegendCardProps> = ({
  color,
  label,
  tooltip,
  children,
  isIndex,
  isFeatured,
  targetLink,
  idForFundDetailsLink,
  isActive,
  onRemove,
  id,
  superLabel,
  date,
}) => {
  const generateInStyleConfig = (featured: boolean) => {
    return {
      backgroundColor: switchTableRowColor(featured, false),
    };
  };

  const wrapLabelWithLink = (label: string, idForFundDetailsLink?: string) => {
    const content = (
      <>
        <p>{label}</p>
        <div className="flex">
          <div className="min-w-14 text-end">{children}</div>
        </div>
      </>
    );
    return idForFundDetailsLink || targetLink ? (
      isMobile && firstTap ? (
        <div
          className={cx(
            style.fundDetails,
            `text-xs text-neutral text-normal ${
              isFeatured || isIndex || isActive ? 'font-bold' : ''
            }`,
          )}
        >
          {content}
        </div>
      ) : (
        <Link
          to={
            (superLabel && targetLink) ||
            buildFundDetailsPath(idForFundDetailsLink || '')
          }
          className={cx(
            style.fundDetails,
            `text-xs text-neutral text-normal ${
              isFeatured || isIndex || isActive ? 'font-bold' : ''
            }`,
          )}
        >
          {content}
        </Link>
      )
    ) : (
      <div className={style.fundDetails}>
        <p
          className={cx(
            'text-xs text-neutral text-normal flex',
            isFeatured || isIndex || isActive ? 'font-bold' : '',
          )}
        >
          {label}
        </p>
        <div className="flex">{children}</div>
      </div>
    );
  };

  const idToRemove = useMemo(() => {
    if (isIndex && id) return id;
    return idForFundDetailsLink || targetLink;
  }, [isIndex, id, idForFundDetailsLink]);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [firstTap, setFirsTap] = useState(true);
  const { app } = useAppContext();
  const isMobile = app.windowWidth <= BREAKPOINTS.LG;

  const handleTooltipVisibleChange = (
    visible: boolean | ((prevState: boolean) => boolean),
  ) => {
    setTooltipVisible(visible);
    if (!visible) {
      setFirsTap(true);
    }
    if (firstTap) {
      setTooltipVisible(true);
      setFirsTap(false);
    }
  };

  const mainComponentPart = (idForFundDetailsLink?: string) => (
    <>
      <Tooltip
        align={{ offset: [0, 6] }}
        placement="bottom"
        trigger={isMobile ? 'click' : 'hover'}
        open={tooltipVisible}
        onVisibleChange={handleTooltipVisibleChange}
        title={
          <p>
            {tooltip}
            {isIndex && (
              <>
                {' ('}
                <b>Index</b>
                {')'}
              </>
            )}
            {isFeatured && (
              <>
                {' ('}
                <b>Focus Fund</b>
                {')'}
              </>
            )}
            {superLabel && (
              <>
                {' ('}
                <b>Portfolio</b>
                {')'}
              </>
            )}
            {date && (
              <>
                <br />
                As of {date}
              </>
            )}
          </p>
        }
        overlayInnerStyle={{ borderRadius: 4, fontSize: 12 }}
        arrowPointAtCenter
      >
        <div
          className={cx(
            'flex items-center h-4 rounded',
            style.legendCard,
            !!superLabel && 'relative',
            {
              [style['has-superscript']]: isIndex,
            },
            {
              [style['is-portfolio']]: superLabel,
            },
          )}
          style={generateInStyleConfig(isFeatured)}
        >
          <div className="w-6">
            <div
              className={cx('w-2 h-2 mx-2 rounded-full', style.circle)}
              style={{ background: color }}
            />
          </div>

          <div className={style.label}>
            {wrapLabelWithLink(label, idForFundDetailsLink)}
          </div>
          {onRemove && idToRemove && (
            <span
              className="hover:text-red-500 text-gray-400 cursor-pointer pl-2"
              onClick={() => {
                if (!isMobile || !firstTap) {
                  onRemove?.(idToRemove, isIndex);
                }
              }}
            >
              <CloseIcon className="h-2.5 w-2.5 fill-[#3B3C53] mr-1.5" />
            </span>
          )}
        </div>
      </Tooltip>
    </>
  );

  return mainComponentPart(idForFundDetailsLink);
};

export default LegendCard;
