import { Modal } from 'antd';
import cx from 'classnames';
import React from 'react';

import Button from '@/components/Button';
import { APP_ACTIONS } from '@/constants';
import { useAppContext } from '@/context/AppContext';

type ModalProps = {
  modalInfo: {
    title: string;
    description: string | React.ReactNode;
    btnName: string;
    succesMessage: string;
    errorMessage: string;
  };
  isVisible: boolean;
  className?: string;
  toggleModal: () => void;
  onClickAction: () => Promise<any | void>;
  closeIcon?: React.ReactNode;
  loading?: boolean;
  children?: React.ReactNode;
  width?: number;
  isUserSelectBucket: boolean;
};

const WrapperMultiSelectBucketModal: React.FCWithChild<ModalProps> = ({
  modalInfo,
  isVisible,
  className,
  loading,
  toggleModal,
  onClickAction,
  children,
  width = 400,
  isUserSelectBucket,
}) => {
  const { dispatch: dispatchApp } = useAppContext();

  const onClick = () => {
    onClickAction()
      .then(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_SUCCESS_MESSAGE,
          payload: { text: modalInfo.succesMessage || '' },
        });
      })
      .catch(() => {
        dispatchApp({
          type: APP_ACTIONS.SET_ERROR_MESSAGE,
          payload: modalInfo.errorMessage || '',
        });
      });
    toggleModal();
  };
  return (
    <Modal
      centered={true}
      width={width}
      open={isVisible}
      onCancel={toggleModal}
      maskClosable={false}
      className={cx(
        'action-modal',
        'max-sm:[&_.ant-modal-header]:shadow-sm max-sm:[&_.ant-modal-footer]:drop-shadow-md',
        className,
      )}
      title={modalInfo.title}
      footer={[
        <Button
          className="font-medium text-sm p-0 text-[#0072e6]"
          size="large"
          type="link"
          key="secondary"
          disabled={loading}
          onClick={toggleModal}
        >
          Cancel
        </Button>,
        <Button
          className="h-10 font-medium w-24 text-[#0072e5] m-0"
          type="primary"
          size="large"
          onClick={onClick}
          key="primary"
          loading={loading}
          disabled={isUserSelectBucket}
        >
          {modalInfo.btnName}
        </Button>,
      ]}
    >
      <div className="text-base not-italic font-normal leading-6 text-[#313341]">
        {modalInfo.description}
      </div>
      {children}
    </Modal>
  );
};

export default WrapperMultiSelectBucketModal;
